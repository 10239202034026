h3.metrics{
  font-weight: $font-weight-regular;
  font-size: $font-size-sm;
  margin-top: 0;
  letter-spacing: 0;
}

.metrics{
  h1{
    margin-bottom: 0.5em;
  }
  p{
    @include media-breakpoint-down(md){
      margin-bottom: 1em;
    }
  }
}

/** Admin Performance Pane **/

.highlight-container{
  height:100%;
  .metric-card{
    height:49%;
    margin-bottom:2%;
  }
  .metric-card:last-child{
    margin-bottom:0;
  }
}