.inline {
  display: flex;

  &--vertical-center {
    align-items: center;
    align-content: center;
  }

  @include media-breakpoint-up(xs) {
    flex-wrap: wrap;
    & > :not(:first-child) {
      margin-left: #{$spacing-unit * 2}px;
      margin-top: 0;
    }
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    > :not(:first-child) {
      //margin-left: 0;
      //margin-top: #{$spacing-unit * 2}px;
    }
    > * {
      //width: 100%;
    }
    > .inline { width: auto; }
  }

  &--right {
    justify-content: flex-end;
  }


  &.inline-right-align-siblings {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    > :first-child {
      flex: 1 1 auto;
    }

    > :not(:first-child) {
      margin-left: #{$spacing-unit * 2}px;
    }

    @include media-breakpoint-down(xs) {
      justify-content: flex-start;

      > :not(:first-child) {
        margin-left: 0;
      }
    }

    &.nowrap {
      flex-wrap: nowrap;
      @include media-breakpoint-down(sm) {
        flex-wrap: nowrap;
      }
      @include media-breakpoint-down(xs) {
        flex-wrap: wrap;
      }
    }

    &.metrics {
      justify-content: space-between;

      > :first-child {
        flex: none;
      }

      > :not(:first-child) {
        margin-left: 0;
      }

      @include media-breakpoint-down(xs) {
        justify-content: flex-start;
        > :first-child {
          flex: 1 1 auto;
        }
      }

      .inline {
        flex-wrap: nowrap;
        @include media-breakpoint-down(xs) {
          flex-wrap: wrap;
          > :not(:first-child) {
            margin-left: 0;
          }
        }
      }
    }

    &.enquiry-header {
      > .inline {
        & > :not(:first-child) {
          margin-top: auto;
        }

        @include media-breakpoint-down(xs) {
          > :not(:first-child) {
            margin-left: 0;
          }
        }
      }

      @include media-breakpoint-down(md) {
        justify-content: flex-start;
        padding-top: 1.5em;
      }
    }
  }

  &.inline--as-footer {
    position: fixed;
    bottom: 1px;
    left: 1px;
    right: 1px;
    padding: 0 2rem 1rem;
    background: linear-gradient(transparent, #fafafa 2rem);
    z-index: 9;

    button {
      padding: 1rem;
      svg {
        margin: 0;
        font-size: 2rem;
      }
      + button {
        margin-left: 1rem;
      }
    }
  }
}

.button-alignment {
  @include media-breakpoint-up(xs) {
    flex-wrap: wrap;
    & > :not(:first-child) {
      margin-left: #{$spacing-unit * 2}px;
      margin-top: 0;
    }
  }
  @include media-breakpoint-down(xs) {
    width: auto;
    flex-wrap: wrap;
    & > :not(:first-child) {
      margin-left: #{$spacing-unit * 2}px;
      margin-top: 0;
    }
    > * {
      width: auto;
    }
  }

  > .tag {
    margin-left: 0;
  }
}

