.add-label{
  form{
    width:100%;
    .label-list{
      margin-top: 0.75rem;
      display: flex;
      flex-wrap: wrap;
      .tag.button-base {
        margin-right: 3px;
      }
    }

    .textfield{
      width:100%;
      display:block;
      border:none;

      .input{
        border:none;
        padding:0;
      }

      input{
        height:30px;
        padding: 0.5rem 0.5rem 0.5rem 0.75rem;
        border-radius: 6px 0 0 6px;
        border: 1px solid $light-grey;
        border-right:none;
      }
    }

    button{
      height:30px;
      width:15%;
      border-radius: 0 6px 6px 0;
      border: 1px solid $button-primary-background;
      background:$button-primary-background;
      color: $white;
    }
  }

  &.task-checklist{
    form{
      button{
        height: 47.5px;
        width:45px;
        border-radius: 0 6px 6px 0;
        border: 1px solid $light-grey;
        background:none;
        color: $light-grey;
        border-left:none;
        padding: 0;

        &.icon{
          border: transparent;
        }

        &:not([disabled]) svg {
          color: $aqua-primary;
      }
      }

      .checklist-form-container{
        margin-top: 0;
        margin-bottom: 16px;
        display:flex;

        svg{
          margin-top:-1px;

        }
      }
    }
  }
}



