.flex-container{
  display:flex;
  flex-wrap: wrap;
  &--justify{
    justify-content:space-between;
    &-center{
      align-items: center;
    }
  }
  &--no-wrap{
    flex-wrap:nowrap;
    &-center{
      flex-wrap:nowrap;
      align-items: center;
    }
  }
  &--white{
    background-color:$white;
  }
  &--whiteish{
    background-color:$white + DD;
  }

  &.full-height{
    height: 100%;
    overflow: hidden;
  }

  .flex-item{
    &.col-3{
      width: 32%;
      margin-right: 1%;
      &:last-child{
        margin-right: 0;
      }
      @include media-breakpoint-down(sm){
        width: 100%;
        margin-right: 0;
      }
    }
  }

  .content-container{
    width: 100%;
    height: 100%;
    display: contents;
  }
}
