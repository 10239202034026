.sign-in {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: #{320 + $spacing-unit * 4}px;
	padding: #{$spacing-unit}px;

	h2{
		font-weight: $font-weight-bold;
		font-size: 1.75em;
		letter-spacing: 0.5px;
	}

	p{
		font-size: 1em;
	}

	.divider-line {
		width: 50px;
		height: 4px;
		background-color: $aqua-primary;
		margin: 16px 0;
	}

	.link {
		font-size: $typography-regular;
	}

	.remember-links {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.button-base {
		&.primary {
			font-size: 1em;
			font-weight: $font-weight-medium;
			text-transform: none;
		}
	}

	.error {
		background-color: $error-foreground;
		padding: #{$spacing-unit}px;
		margin-top: #{$spacing-unit * 2}px;
		margin-bottom: #{$spacing-unit}px;
	}

	.error-text {
		color: $white;
		font-weight: $font-weight-medium;
	}
}
