.image-upload-card {
    display:inline-block;
    background: $white;
    padding: #{$spacing-unit * 2}px;
    border: 1px solid $grey-primary;
    position: relative;

    + .image-upload-card {
		margin-top: #{$spacing-unit * 2}px;
    }


    .image-thumbnail {
        height: 130px;
        width: 260px;
        text-align: center;
        background-color: $grey-primary;
    }

    .image-documents {
        color: $white;
        font-size: 7rem;
        padding-top: #{$spacing-unit * 2}px;
    }

    .image-description {
        text-align: center;
        padding-top: 20px;

    }
}
