.stepper{
  /* Steps */
  .step {
    position: relative;
    min-height: 1em;

    + .step {
      margin-top: 2.5rem;
    }
     > div:first-child {
      position: static;
      height: 0;
    }
    > div:not(:first-child) {
      margin-left: 2.5rem;
      padding-left: 1em;
    }
    &--active {
      .circle {
        background-color: $aqua-primary;
      }
    }
  }

  /* Circle */
  .circle {
    background: $grey-red;
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    font-weight: $font-weight-medium;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 0 3px #fff;
  }

  /* Vertical Line */
  .circle:after {
    content: ' ';
    position: absolute;
    display: block;
    top: 1px;
    right: 50%;
    bottom: 1px;
    left: 50%;
    height: 100%;
    width: 1px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-color: $grey-red;
  }
  .step:last-child .circle:after {
    display: none
  }

  /* Stepper Titles */
  .ash-details {
    font-size: $font-size-sm;
    line-height: 1.5rem;
    font-weight: $font-weight-medium;
  }
  .caption {
    font-size: $typography-small;
  }
}