.address-autocomplete {
    display: inline-flex;
    width: 100%;

    .input-container {
        position: relative;
        width: 100%;
    }

    .paper {
        position: absolute;
        z-index: 100;
        left: 0;
        right: 0;
        padding: #{$spacing-unit}px 0;
    }

    .item {
        padding: #{$spacing-unit}px #{$spacing-unit * 2}px;
    }

    .readonly {
        .value {
            color: $dark-grey2;
            font-style: $font-style-italic;
            display: inline-block;
        }
    }
}

.input-search {
    color: $denim-primary;
    padding: 3px;
}
