.left-label{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1.4em;
  align-items:self-start;
  .label-container{
    width: 23%;
    padding-right: 0.5em;
    margin-top: auto;
    margin-bottom: auto;
    @include media-breakpoint-down(xs){
      width: 100%;
      margin-bottom: 0.25em;
    }
    .form-label{
      line-height: 1.5;
    }
  }
  .field-container{
    width: 77%;
    margin-top: auto;
    margin-bottom: auto;
    @include media-breakpoint-down(xs){
      width: 100%;
    }
    & > .user-circle:not(:first-child){
      margin-top: 0;
    }

    & > :not(:first-child){
      margin-top: 0.5em;
    }

  }
  .multi-fields{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    .small{
      width: 29%;
    }
    .large{
      width: 69%;
    }
  }

  &purple{

  }
}
