.appointment {
	border-radius: #{$border-radius}px;
	overflow: hidden;

	@include media-breakpoint-up(sm) {
		display: flex;
	}

	+ .appointment {
		margin-top: #{$spacing-unit * 2}px;
	}

	.time {
		color: $white;
		background-color: $app-background;
		padding: #{$spacing-unit * 2}px;
		text-align: center;

		@include media-breakpoint-up(sm) {
			flex: 0 0 160px;
		}
	}

	.description {
		color: $black;
		background-color: $white;
		padding: #{$spacing-unit * 2}px;

		@include media-breakpoint-up(sm) {
			flex: 1 1 auto;
		}
	}
}

.paper {
	.appointment {
		.description {
			background-color: $almost-white;
		}
	}
}
