//Metric Content Component
.metric-content{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p{
    //margin:0;
  }
  h3{
    color: $denim-primary;
    font-size: $font-size-xlg;
    margin-bottom: 0.5em;
  }
}
