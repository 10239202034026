.data-table {
  .iconContainer {
    text-align: center;
    border: 3px solid #757575;
    border-radius: 5px;
    width: 50px !important;
    height: 50px;
  }

  &--filter-content {
    max-height: calc(90vh - 120px);
    overflow: hidden;
    overflow-y: auto;

    label span {
      line-height: 1.15;
      font-size: 0.875rem;
    }
  }
}
