/** Ratings Bar **/

.rating-bar-container{
  padding:1em 0;
  position:relative;

  .score-indicator-container{
    position: absolute;
    top: 0.55em;
    bottom: 0.55em;
    left: 0;
    z-index: 1;
    background: #000000;
    height: 164px;
    animation: indication 2s ease-in-out;
  }
  .score-indicator{
    position:relative;
    width:3px;
    height:100%;
    &::before,
    &::after{
      width:8px;
      height:8px;
      background:$black;
      content:"";
      display:block;
      margin-left:-4px;
      border-radius:4px;
      position:absolute;
      left:50%
    }
    &::after {
      bottom:0;
    }
  }
}

.rating-card{
  text-align:center;
  width:20%;
  position:relative;

  .center-content{
    background: $fail;
    display: flex;
    height: 150px;
    width: auto;
    flex-direction: column;
    justify-content: center;
    padding:0.75em;
  }

  svg{
    color:$white;
    height:100%;
    width: 100%;
  }
  p{
    display: inline-block;
    width: 100%;
    color: $fail;
    background: #ffffff;
    font-weight: bold;
    padding: 0.5em 0;
  }
  &.forty{
    .center-content{
      background:$bad;
    }
    p{
      color:$bad;
    }
  }
  &.sixty{
    .center-content{
      background:$ok;
    }
    p{
      color:$ok;
    }
  }
  &.eighty{
    .center-content{
      background:$good;
    }
    p{
      color:$good;
    }
  }
  &.hundred{
    .center-content{
      background:$great;
    }
    p{
      color:$great;
    }
  }
}

@keyframes indication{
  0%{left:0;}
}
