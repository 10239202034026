.sort-by-container{
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;

  @include media-breakpoint-up(sm) {
    max-width:350px;
  }
  .label{
    padding-right: 0.5em;
    width:initial;
    white-space: nowrap;
    &.brand{
      font-size: $font-size-lg;
      font-weight: $font-weight-medium;
    }
  }
  select,
  .select{
    min-width:150px;
  }
}
