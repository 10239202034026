.autocomplete {
	position: relative;
	flex: 1 1 auto;
	.field-container {
		border: 1px solid #D5D5D5;
		background: white;
		padding: 0.7em;
		border-radius: 3px;
	}
}

.hover-search-results {
	@include media-breakpoint-up(sm) {
		min-width: 300px;
	}
	position: absolute;
	z-index: 10;
	left: 0;
	right: 0;
	top: calc(100% - 2px);
	border: 1px solid $light-grey;
	padding: #{$spacing-unit}px 0;

	.item {
		display: flex;
		padding: #{$spacing-unit}px #{$spacing-unit * 2}px;
	}

	.item-label {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.view-all {
		margin-bottom: -#{$spacing-unit}px;
		padding-left: 40px;
	}
}
