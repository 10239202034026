.icon {
	font-size: $typography-icon;
	color: $denim-primary !important;
}

.tick-icon {
	font-size: $typography-icon;
	color: $green-primary !important;
}

.icon-blue{
	color: $aqua-primary;
}

.warning-icon{
	color: $orange-primary;
}

.attention-icon{
	color: $denim-tertiary;
}

.plus-icon{
	-ms-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.star-icon{
	color: $star-yellow;
}