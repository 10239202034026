.notification-popover {
	.badge {
		top: #{$spacing-unit / 0.8}px;
		right: #{$spacing-unit / 0.8}px;
		width: #{$spacing-unit * 2.5}px;
		height: #{$spacing-unit * 2.5}px;
		background-color: $green-primary;
		font-size: 14px;
	}
}

.notification-padding{
	padding: #{$spacing-unit * 3}px;
}

.popover-container {
	min-width: 350px;
	max-width: 440px;
	width: 100%;
	max-height: calc(100vh - 32px);
	background-color: #fafafa;

	.headline {
		padding: #{$spacing-unit * 3}px;
	}

	.scrollable-content {
		overflow: hidden;
		max-height: calc(100vh - 160px);
	}

	.notification-list {
		list-style: none;
		max-height: calc(100vh - 192px);
		margin: 0;
		overflow-y: auto;
		overflow-x: hidden;
		padding: 0;
		> li {
			border-style: solid;
			border-left-color: $denim-primary;
			border-right-color: #cccccc;
			border-top-color: #cccccc;
			border-bottom-color: #cccccc;
			border-width: 1px 0px 0px 5px;
			cursor: pointer;
			transition: border-width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
				box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
				background 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
				transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		}
		> li:last-child {
			border-width: 1px 0px 1px 5px;
		}
		> li:hover {
			border-left-color: $denim-primary;
			border-left-width: 10px;
			box-shadow: -2px 2px 2px 2px #e2e2e2;
			z-index: 1;
		}

		.notification {
			background: none;
			border: none;
			cursor: pointer;
			display: inline-block;
			padding: #{$spacing-unit * 2}px;
			overflow: hidden;
			width: 90%;
			text-align: left;

			.title-container{
				margin-bottom: 0.4em;
			}

			.title {
				font-size: $font-size-sm;
				letter-spacing: 0.02em;
				color: $denim-tertiary;
			}
			.description {
				font-size: $font-size-reg;
				font-weight: lighter;
				color: #2F2F2F;
			}
		}

		.clear-button {
			padding: 7px;
			> span:first-child > svg {
				height: 18px;
				width: 18px;
			}
		}
		.clear-button-container {
			justify-self: center;
			align-self: center;
		}
		.clear-loading {
			margin-right: 6px;
		}
	}

	.spacing{
		padding: #{$spacing-unit * 1}px;
	}
}
