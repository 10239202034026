.drawer {
	> div {
		width: #{$size-drawer-width}px;
		@include media-breakpoint-up(md) {
			position: relative;
			height: calc(100vh - #{$size-footer-height}px);
		}
		transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
		-webkit-transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
		overflow: hidden;
	}

	&--minimized {
		> div {
			width: #{$size-drawer-width-minimized}px;
		}
	}
}

.drawer-list {
	padding-top: 0;
	overflow: hidden;
	overflow-y: auto;

	&--with-footer {
		padding-top: 0;
		margin: 0px 0px 72px 0px;
		overflow: auto;
	}

	.title {
		display: flex;
		align-items: center;
		height: #{$size-header-height}px;
		padding: 0 #{$spacing-unit/2}px;
		> :not(:first-child){
			margin-left:#{$spacing-unit}px;
		}
	}
	.title-text {
		flex: 1 0 auto;
	}
	.title-icon {
		width: 32px;
	}
	.burger {
		width: 22px;
		height: 22px;
	}
	.burger-button {
		width:40px;
		height:40px;
	}
}
.drawer-list-footer {
	display: relative;
	position: absolute;
	bottom: 70px;
	padding: #{$spacing-unit * 2}px #{$spacing-unit * 2}px;
}

div.drawer-list-item {
	height: 48px;
	padding-right: 0;
	transition: padding-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
	-webkit-transition: padding-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

	&--active {
		background-color: $app-selected;
		border-left: 3px solid $app-active;
	}
	&--nested {
		padding-left: #{$spacing-unit * 4}px;
	}
	&--minimized {
		padding-left: #{$spacing-unit * 2}px;
	}
	.icon {
		width: 16px;
		font-size: $typography-subheading-size;
		margin: 0;
		&--active {
			margin-left: -3px;
		}
	}
	.text {
		font-size: $typography-regular;
		color: $dark-grey;
		overflow: hidden;
		white-space: nowrap;
		&--active {
			color: $app-active;
			font-weight: $font-weight-medium;
		}
	}
}
