.task-card{
  display:inline-block;
  background: $white+CC;
  padding: #{$spacing-unit * 2}px;
  border-bottom: 5px solid $denim-primary;
  position: relative;
  @include media-breakpoint-down(sm){
    padding: #{$spacing-unit}px 0;
  }

  .fab.check {
    position: absolute;
    top: -20px;
    right: -20px;
    z-index:5;
		background-color: $button-primary-background;
		border-color: $button-primary-background;
    color: $button-primary-foreground;
        &[disabled] {
            background-color: $button-primary-background-disabled;
        }
    &.incomplete{
            background-color: $white;
    }
    > span > svg {
      height:20px;
      width:20px;
    }
		&:hover {
			background-color: $button-primary-background-hover;
			border-color: $button-primary-background-hover;
			color: $button-primary-foreground-hover;
		}
  }

  .task-star {
    color: $star-yellow;
    height:42px;
    width:42px;
        //margin-left: -12px;
        //margin-top: -12px;
        //margin-bottom: -12px;
    padding:0;
  }
  .task-repeat {
        //color: $denim-primary;
  }
    .due-date{
      font-size: $typography-regular;
      color: $almost-black;
      &.overdue{
        color: $red-primary;
      }
  }

  .record-id{
    font-weight: $font-weight-light;
    font-size: $font-size-reg;
    margin: 0;
    line-height: 2;
    @include media-breakpoint-down(sm){
      display: none;
    }
    &-mobile{
      font-size: 14px;
    }
        &.restrict {
            max-width: 50%;
            overflow: hidden;
            white-space: pre;
            text-overflow: ellipsis;
        }
  }

  .task-heading{
    color: $denim-primary;
    margin:0;
    font-size: $font-size-md;
    padding-bottom: 0.3em;
  }

  .task-description{
    font-size: $font-size-reg;
  }

  .task-details{
    @include media-breakpoint-down(sm){
        display: none;
    }

    .table-key{
      margin: 0 !important;
    }
  }

  .task-assigned{
    font-size: $typography-regular;
    color: $denim-primary;
  }
  h2{
    letter-spacing:0.02em;
  }

  h3{
    font-size: $font-size-sm;
    letter-spacing:0.02em;
    color:$denim-tertiary;
    margin:0.5em 0;
  }

  .actions{
    & > * {
      margin-left: 0.5em;
    }
  }


  &.list{
    .task-header-desktop{
      @include media-breakpoint-down(sm){
        display: none;
      }
    }
    .task-header-mobile{
      @include media-breakpoint-up(md){
        display: none;
      }
      > .inline{
        @include media-breakpoint-up(xs) {
          flex-wrap: wrap;
          & > :not(:first-child) {
            margin-left: #{$spacing-unit * 2}px;
            margin-top: 0;
          }
        }
        @include media-breakpoint-down(xs) {
          width: auto;
          flex-wrap: wrap;
          & > :not(:first-child) {
            margin-left: #{$spacing-unit * 2}px;
            margin-top: 0;
          }
          > * {
            width:auto;
          }
        }
      }
    }
    .flex-container{
      justify-content: space-between;
      align-content: center;
      align-items: center;
    }
  }

  //This style is for TaskCard on the workqueue and the goals for the metric dashboard
  &.dash-card{
    padding: #{$spacing-unit * 3}px;
    @include media-breakpoint-up(md){
      width: 15.66%;
    }

    @include media-breakpoint-down(md) {
      width: 32.33%;
      margin-top: 1.5rem;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-right:0;
      &:first-child{
        margin-top:0;
      }
      padding: #{$spacing-unit * 1.2}px;
    }
    .record-id {
      font-size: $typography-body-size;
      @include media-breakpoint-down(sm) {
        display: block;
      }
    }
    .task-heading{
      color: $denim-primary;
      margin:0;
      font-size: $font-size-xlg;
    }

    .task-description{
      font-size: $font-size-xs;
    }

    .comments{
      display: flex;
      font-size: $typography-regular;
      color: $denim-primary;
      svg{
        margin: 0;
        font-size: $typography-display3;
      }

      & > span {
        position: absolute;
        margin-top: 10px;
        margin-left: 6px;
        width: 28px;
        text-align: center;
      }
    }
  }
  &.archived {
    * {
      text-decoration: line-through;
    }
  }
    .ViewTaskLink {
        display: block;
        text-align: center;
        background: $denim-tertiary;
        color: white;
        padding: 6px;
        border-radius: 3px;
    }
}
