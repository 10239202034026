$typography-title-size: 1.1rem;
$typography-subheading-size: 1rem;
$typography-body-size: 1.1rem;
$typography-button: 1rem;
$typography-validation: 0.875rem;
$typography-small: 0.75rem;
$typography-display1: 1.6rem;
$typography-display2: 1.8rem;
$typography-display3: 2.4rem;
$typography-display4: 3.2rem;
$typography-regular: 14px;
$typography-icon: 1rem;
$font:'Roboto', 'Helvetica', 'Arial', sans-serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-style-italic: italic;

$font-size-xxlg: 2rem;
$font-size-xlg: 1.6rem;
$font-size-lg: 1.4rem;
$font-size-md: 1.3rem;
$font-size-sm: 1.25rem;
$font-size-xs: 1rem;
$font-size-reg:0.975rem;



