.checkbox {
	display: flex;
	align-self: center;

	&.checked {
		input, svg {
			color:$aqua-primary;
		}
	}

	&.rejected.checked{
		input, svg {
			color:$red-primary;
		}
	}

	&.disabled {
		svg
		{
			color:$grey-primary;
		}
	}

	.form-label {
		margin: 0 #{$spacing-unit}px 0 -#{$spacing-unit}px;
		display:inline-flex;
	}

	.control {
		width: 36px;
		height: 36px;
		border-radius: #{$border-radius}px;
	}

	.validation-label {
		&.error {
			color: $error-foreground;
		}
		&.suggested {
			color: $warning-foreground;
		}
		&.optional {
			color: $good-foreground;
		}
		margin-top: 12px;
	}

	&.error,
	&.suggested,
	&.optional {
		border-width: 1px;
		border-radius: #{$border-radius}px;
		border-style: solid;
		padding: 0 #{$spacing-unit}px;
	}

	&.error {
		border-color: $error-foreground;
		color: $error-foreground;
	}
	&.suggested {
		border-color: $warning-foreground;
		color: $warning-foreground;
	}
	&.optional {
		border-color: $good-foreground;
		color: $good-foreground;
	}
}
