.select {
	width: 100%;

	.control {
		background-color: $white;
		padding: #{$spacing-unit}px #{$spacing-unit * 3}px #{$spacing-unit}px #{$spacing-unit}px;
		width: calc(100% - #{$spacing-unit * 4 + 2}px);
		border-radius: #{$border-radius}px;
		border: 1px solid $light-grey;
		&:focus {
			border-color: $button-tertiary-foreground;
			border-radius: #{$border-radius}px;
		}
	}

	.outer-control {
		border-radius: #{$border-radius}px;
		&:before {
			display: none;
		}
		&:after {
			display: none;
		}
		padding: 0;
	}

	.validation-label {
		border-width: 0 1px 1px 1px;
		border-radius: 0 0 #{$border-radius}px #{$border-radius}px;
		border-style: solid;
		margin: 0 3px;
		padding: #{$spacing-unit}px;
		&.error {
			color: $error-foreground;
			border-color: $error-foreground;
		}
		&.suggested {
			color: $warning-foreground;
			border-color: $warning-foreground;
		}
		&.optional {
			color: $good-foreground;
			border-color: $good-foreground;
		}
	}

	&.error,
	&.suggested,
	&.optional {
		border-width: 1px;
		border-radius: #{$border-radius}px;
		border-style: solid;
		padding: #{$spacing-unit}px;
	}

	&.error {
		border-color: $error-foreground;
		color: $error-foreground;
	}
	&.suggested {
		border-color: $warning-foreground;
		color: $warning-foreground;
	}
	&.optional {
		border-color: $good-foreground;
		color: $good-foreground;
	}
}

.readonly{
	.value{
		color: $dark-grey2;
		font-style: $font-style-italic;
		display: inline-block;
	}
}
