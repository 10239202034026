.thumbnail-container{
    width: 24%;
    margin-right: 1%;
    margin-top: #{$spacing-unit * 2}px;
    @include media-breakpoint-up(md) {
        width: 32.33%;
    }
    @include media-breakpoint-down(md){
        width: 49%;
    }
    @include media-breakpoint-down(xs){
        width: 100%;
    }
    .file-upload-card{
        width: 100%;
        margin-right: auto;
    }
}

.file-upload-card {
    background: $white;
    padding: #{$spacing-unit * 2.5}px #{$spacing-unit * 2.5}px;
    border: 1px solid $grey-primary;
    position: relative;
    margin-bottom: #{$spacing-unit * 1}px;
    width: calc(25% - #{$spacing-unit * 1}px);
    min-width: 130px;
    margin-right: #{$spacing-unit * 1}px;
    color: inherit;
    text-decoration: none;
    display: block;
    align-self: auto;

    @include media-breakpoint-down(md) {
        width: calc(33.33% - #{$spacing-unit * 1}px);
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
        margin-right: 0;
    }

    &:hover {
        cursor: pointer;
        transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }

    .file-documents {
        color: $grey-primary;
        font-size: 5.5rem;
        display: block;
        margin-right: auto;
        margin-left: auto;
        width: 64px;
        height: 64px;
        object-fit: contain;
    }

    .filename-description {
        text-align: center;
        padding-top: 0.5em;
        word-break: break-all;
    }
    &:after {
        position: absolute;
        top: 8px;
        right:0;
    }
    &.mini {
        width: auto;
        flex: 1 1 auto;
        padding: 8px;
        > * {
            display: inline;
            padding: 0;
            vertical-align: middle;
        }
        svg {
            font-size: 1.25em;
        }
        p {
            margin: 0 6px;
        }
    }
    .remove-upload {
        color: $red-primary;
        position: absolute;
        top: -#{$spacing-unit}px;
        right: -#{$spacing-unit}px;
        border: 1px solid;
        background: white;
        font-size: 12px;
    }
}
