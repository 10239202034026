.tabs {
	[component='indicator']{
		background-color: $denim-primary;
		padding: 0;
	}

	button{
		font-size: $font-size-sm;
		text-transform: none;
		&[aria-selected=true]{
			font-weight: $font-weight-medium;
			color: $black;
		}

		&[aria-selected=false]{
			font-weight: $font-weight-regular;
			color: $dark-grey2;
		}

		span{
			padding: 0.5em;
		}
		@include media-breakpoint-down(xs) {
			font-size: $font-size-xs;
		}
	}

	div{
		width: auto;
	}
}