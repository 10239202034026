button.button-base,
div.button-base {
	box-shadow: none;
	border-radius: #{$spacing-unit * 4}px;
	padding: #{$spacing-unit}px #{$spacing-unit * 3.5}px;
	border-width: 1.5px;
	border-style: solid;

	svg {
		font-size: 0.975rem;
		margin-right: 0.35rem;

		&[class^='MuiCircularProgress-svg'] {
			margin-right: 0;
		}
	}

	span.button-txt + svg {
		margin-left: 0.35rem;
		margin-right: 0;
	}

	&.icon-button {
		padding: 12px;
		border-radius: 9999px;
		min-width: auto;
		margin-bottom: 0;
		.button-label{
			width: auto;
			min-height: auto;
		}

		svg {
			font-size: 1.5em;
			margin-right: 0;
		}

		&.white {
			background-color: $white;
			border: none;

			svg {
				font-size: 1.15em;
				margin-right: 0;
				color: $button-primary-background;
			}

			.star-icon {
				color: $star-yellow;
			}
		}
	}

	&.shadow:not([disabled]):not(.disabled) {
		-webkit-box-shadow: 0px 5px 16px -10px rgba(92, 92, 92, 1);
		-moz-box-shadow: 0px 5px 16px -10px rgba(92, 92, 92, 1);
		box-shadow: 0px 5px 16px -10px rgba(92, 92, 92, 1);
	}

	&.tag {
		border-radius: 4px;
		display: flex;
		align-items: center;
		background-color: $button-primary-background;
		border-color: $button-primary-background;
		color: $button-primary-foreground;
		font-size: 0.75em;
		padding: 6px 16px;
		margin-bottom: 0.5em;
		text-decoration: none;
		font-style: normal;
		width: min-content;

		&[disabled],
		&.disabled {
			background-color: $button-primary-background-disabled;
			pointer-events: none;
		}

		> .button-label {
			white-space: nowrap;
			line-height: 1.563
		}

		.icon-button {
			height: 25px;
			width: 25px;
			margin-left: 8px;
			border: 1px solid #ffffff;
			//background-color: $button-primary-background;
			background-color: #00000022;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover {
				//background-color: $button-primary-background-hover;
				background-color: #00000033;
				color: $button-primary-foreground-hover;
				cursor: pointer;
			}

			> svg {
				margin: 0;
				height: 15px;
				width: 15px;
				color: #ffffff;
			}
		}

		.button-label {
			min-height: unset;
		}
	}

	&.primary {
		background-color: $button-primary-background;
		border-color: $button-primary-background;
		color: $button-primary-foreground;

		&:hover {
			background-color: $button-primary-background-hover;
			border-color: $button-primary-background-hover;
			color: $button-primary-foreground-hover;
		}

		&[disabled],
		&.disabled  {
			background-color: $button-primary-background-disabled;
		}
		.icon-button {
			background-color: opacify($button-primary-foreground, 0.5);
		}
		&.icon-button.white {
			&:hover {
				background-color: #eeeeee;
			}
		}
	}

	&.secondary {
		background-color: $button-secondary-background;
		border-color: $button-secondary-border;
		color: $button-secondary-foreground;

		&:hover {
			background-color: $button-secondary-background-hover;
			border-color: $button-secondary-border-hover;
			color: $button-secondary-foreground-hover;
		}

		&[disabled],
		&.disabled  {
			background-color: $button-secondary-background-disabled;
		}
		.icon-button {
			background-color: $button-secondary-foreground + AA;
		}
	}

	&.tertiary {
		background-color: $button-tertiary-background;
		border-color: $button-tertiary-background;
		color: $button-tertiary-foreground;

		&:hover {
			background-color: $button-tertiary-background-hover;
			border-color: $button-tertiary-foreground-hover;
			color: $button-tertiary-foreground-hover;
		}

		&[disabled],
		&.disabled  {
			background-color: $button-tertiary-background-disabled;
		}

		&.orange {
			background-color: $orange-primary;
			border-color: $orange-primary;
			color: $button-tertiary-foreground;

			&:hover {
				background-color: $button-tertiary-background-hover;
				border-color: $button-tertiary-background-hover;
				color: $button-tertiary-foreground-hover;
			}
		}
	}

	&.quaternary {
		background-color: $button-quaternary-background;
		border-color: $button-quaternary-background;
		color: $button-quaternary-foreground;

		&:hover {
			background-color: $button-quaternary-background-hover;
			border-color: $button-quaternary-background-hover;
			color: $button-quaternary-foreground-hover;
		}

		&[disabled],
		&.disabled  {
			background-color: $button-quaternary-background-disabled;
		}
	}

	&.urgent {
		background-color: $button-urgent-background;
		border-color: $button-urgent-background;
		color: $button-urgent-foreground;

		&:hover {
			background-color: $button-urgent-background-hover;
			border-color: $button-urgent-background-hover;
			color: $button-urgent-foreground-hover;
		}

		&[disabled],
		&.disabled {
			background-color: $button-quaternary-background-disabled;
		}
	}

	&.confirmation {
		background-color: $button-confirmation-background;
		border-color: $button-confirmation-background;
		color: $button-confirmation-foreground;

		&:hover {
			background-color: $button-confirmation-background-hover;
			border-color: $button-confirmation-background-hover;
			color: $button-confirmation-foreground-hover;
		}

		&[disabled],
		&.disabled  {
			background-color: $button-confirmation-background-disabled;
		}
	}

	&.decline {
		background-color: $red-primary;
		border-color: $red-primary;
		color: $button-urgent-foreground;

		&:hover {
			background-color: $red-tertiary;
			border-color: $red-tertiary;
			color: $button-urgent-foreground-hover;
		}

		&[disabled],
		&.disabled  {
			background-color: $button-urgent-background-disabled;
		}
	}

	&.upload {
		background-color: $grey-secondary;
		border-color: $grey-secondary;
		color: $dark-grey;
		border-radius: 0px;
		@include media-breakpoint-down(xs){
			width: 100%;
		}

		&:hover {
			background-color: $grey-primary;
			border-color: $grey-primary;
			color: $dark-grey;
		}
	}

	&.search-button {
		width: 10px;
		height: 38px;
		border-radius: 0 5px 5px 0;
		padding: 0;
		background-color: $button-primary-background;
		border-color: $button-primary-background;
		color: $button-primary-foreground;
		margin: 0;

		&:hover {
			background-color: $button-primary-background-hover;
			border-color: $button-primary-background-hover;
			color: $button-primary-foreground-hover;
		}
	}

	&.modal-action {
		.button-label {
			text-transform: uppercase;
		}

		background-color: transparent;
		border-width: 0px;
		border-radius: #{$spacing-unit}px;
		padding: #{$spacing-unit / 2}px #{$spacing-unit}px;

		&--ok {
			color: $green-primary;
		}

		&--cancel {
			color: $red-tertiary;

		}

		&--clear {
			color: $orange-primary;
			.button-label {
				font-weight: $font-weight-medium;
				//text-transform: none;
			}
		}


		&:hover {
			background-color: $light-grey;
		}
	}

	//Pagination
	&.pagination {
		display: inline-block;
		padding-inline-start: 0;
		&-primary {
			padding: 0 6px;
			background-color: $white;
			color: $black;
			margin: 0 3px;
			border: none;
			border-radius: 10px;
			min-width: 42px;
			text-decoration: none;
			text-align: center;
			&:hover {
				background-color: $grey-secondary;
				cursor: pointer;
			}
			svg {
				margin: 0;
			}
		}
		&-disabled {
			opacity: 0.25;
			&:hover {
				cursor: default;
				background-color: $white;
			}
			display: inline-flex;
			justify-content: center;
			align-self: center;
		}
		&-current {
			background-color: $aqua-primary;
			border-radius: 10px;
			padding: 0 6px;
			color: $white;
			margin: 0 3px;
			border: none;
			min-width: 42px;
		}

	}

	.button-label {
		text-transform: capitalize;
		letter-spacing: 0.02rem;
		line-height: normal;
		min-height: 30px;
		font-size: $font-size-xs;
		font-weight: $font-weight-regular;
	}

	&--lg {
		padding: #{$spacing-unit * 1.5}px #{$spacing-unit * 4}px;
		font-size: $font-size-sm;
		.button-label {
			font-size: $font-size-sm;
		}
	}

	&--tiny {
		padding: #{$spacing-unit * 0.5}px #{$spacing-unit * 1}px;
		&.tag{
			padding: #{$spacing-unit * 0.25}px #{$spacing-unit * 0.75}px !important;
		}
		//margin-bottom: 1em;
		font-size: $typography-small;
		.button-label {
			font-size: $typography-validation;
		}
	}
}

.inline {
	.button-base {
		align-self: center;
	}
}
