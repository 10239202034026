  .add-label.task-checklist {
    width: 100%;
    form > div > div {
      padding: 0;
    }
  }

  .checklist-item {
    border-radius: 6px;

    > div {
        padding-left: 10px;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }

    .checkbox .form-label {
      margin-left: 6px;
    }
}
