.user-circle {
	height: 45px;
	width: 45px;
	background: #7bdbff;
	border-radius: 100px;
	font-size: $typography-button;
	font-weight: $font-weight-medium;
	text-align: center;
	color: $white;
	font-style: normal;
	display: inline-block;
	position: relative;
	margin-right: 0.5em;
	> * {
		line-height: 44px;
	}
	&:hover > :nth-child(2) {
		opacity: 1;
	}

	.delete {
		cursor: pointer;
		border-radius: 100%;
		background: $white;
		color: $denim-primary;
		display: inline-block;
		opacity: 0;
		position: absolute;
		height: 45px;
		width: 45px;
		left: 0;
		top: 0;
		transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		> svg {
			height: 20px;
			width: 20px;
			margin: 12px 0px 0px 0px;
		}
	}

	.abbreviation {
		transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	}

	&:last-child{
		margin-right: 0;
	}
}
