.paper {
	padding: 0 #{$spacing-unit * 2}px #{$spacing-unit * 2}px;
	@include media-breakpoint-down(sm){
		padding: 0 #{$spacing-unit * 1}px #{$spacing-unit * 1}px;
	}
}

.runsheet-paper {
	max-width: 1200px;
	margin: 0 auto;
	padding: 0;
	display: block;
	h2{
		text-align: center;
	}
	h2:nth-of-type(n+2){
		&:before{
			content: '';
			width: 100%;
			height: 0.5px;
			border: 0.5px solid $grey-secondary;
			position: absolute;
			top: -2em;
			left: 0
		}
		position: relative;
		margin-top: 3.5em;
	}
}
