.app-bar {
	top: 0;
	left: auto;
	right: 0;
	position: absolute;
	display: flex;
	z-index: 1100;
	box-sizing: border-box;
	flex-shrink: 0;
	flex-direction: column;
	width: 100%;
	height: #{$size-header-height}px;
	box-shadow: none;
	background-color: $white;
	border-bottom: 1px solid $light-grey;
	transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
		margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
	@include media-breakpoint-up(md) {
		margin-left: #{$size-drawer-width}px;
		width: calc(100% - #{$size-drawer-width}px);
	}

	&--minimized {
		margin-left: #{$size-drawer-width-minimized}px;
		@include media-breakpoint-up(md) {
			width: calc(100% - #{$size-drawer-width-minimized}px);
		}
	}

	.toolbar {
		height: #{$size-header-height}px;
		color: inherit;
		justify-content: flex-end;
		padding-left: #{$spacing-unit * 3}px;
		padding-right: #{$spacing-unit * 4}px;
		@include media-breakpoint-down(sm) {
			padding-left: #{$spacing-unit}px;
			padding-right: 24px;
			justify-content: space-between;
		}
		@include media-breakpoint-down(xs) {
			padding-left: #{$spacing-unit}px;
			padding-right: #{$spacing-unit}px;
		}
	}

	.burger {
		width: 16px;
	}
}

.desktop-search {
	flex: 1 1 auto;
	margin-right: #{$spacing-unit * 3}px;
}

.mobile-search-overlay {
	z-index: $z-index-modal;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
}

.mobile-search-paper {
	position: fixed;
	z-index: $z-index-modal;
	width: 100%;
	min-height: #{$size-header-height}px;
	padding: #{$spacing-unit * 2}px;
	right: 0;
	transition: opacity 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
		width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

	&--hidden {
		opacity: 0;
		width: 0;
		pointer-events: none;
	}
}

.header-actions {
	> :not(:first-child) {
		margin-left: 15px;
	}

	.fab {
		width: 40px;
		height: 40px;
		flex: 0 0 auto;
		box-shadow: none;
		background-color: $button-primary-background;
		border-color: $button-primary-background;
		color: $button-primary-foreground;
		&:hover {
			background-color: $button-primary-background-hover;
			border-color: $button-primary-background-hover;
			color: $button-primary-foreground-hover;
		}
	}

	.search-icon {
		color: $app-active;
	}

	.user {
		background-color: $app-background;
	}

	.user-arrow {
		position: absolute;
		color: $black;
		right: -12px;
		bottom: -8px;
		font-size: $typography-button;
	}
}

.header-search {
	position: relative;
	max-width: 600px;

	.input-wrapper {
		padding: #{$spacing-unit}px;

		&--elevated {
			border: 1px solid $light-grey;
		}
	}
}

.view-header {
	display:flex;
	justify-content: space-between;
	align-items: center;
	.separator{
		@include media-breakpoint-down(xs) {
			display: none;
		}
	}

	.inline{
		@include media-breakpoint-down(xs) {
			> :not(:first-child) {
				margin-top: 3px;
				margin-left: 0;
			}
		}
	}

	.title {
		color: $denim-primary;
	}
	.subtitle {
		color:$dark-grey;
	}

	&--record{
		@include media-breakpoint-down(md) {
			display: block;
		}

		.header-children{
			> :last-child{
				margin-left: 1em;
			}

			> :first-child{
				margin-left: 0;
			}

			@include media-breakpoint-down(md) {
				padding-top: 1em;
			}
		}
	}
}

.inline-right-align-siblings.dashboard-header{
	@include media-breakpoint-down(lg) {
		display: block;
	}

	> :not(:first-child) {
		margin-left: 0;
	}

	button{
		@include media-breakpoint-down(md) {
			float: left;
			margin-top: 1.5em;
		}
	}
}

.inline-right-align-siblings.contact-header{
	padding: 4px 9px;
	@include media-breakpoint-down(lg) {
		display: none;
	}
}

main {
	div.contact-header-mobile {
		margin-bottom: 0.75em;
		padding: 0 10px!important;
		@include media-breakpoint-up(lg) {
			display: none;
		}
	}
}

.enquiry-header{
	@include media-breakpoint-down(sm){
		padding: 0 #{$spacing-unit * 2}px #{$spacing-unit * 2}px;
	}
}
