.notes-container{
  max-height: 500px;
  //height: 50vh;
  overflow-y: auto;
  padding-right: 0;
}
.notes-readonly{
  font-weight: $font-weight-regular;
  pre{
        font-family: 'Roboto', 'Helvetica Neue', sans-serif;
    white-space: pre-wrap;
    font-weight: 300;
        margin-top: 0;
  }

  h5{
    margin-bottom: 0.5em;
    font-weight: $font-weight-regular;
  }

  p{
    font-weight: $font-weight-light;
    letter-spacing: initial;
    font-size: 1rem;
  }
}
