.search-field{
  width: 100%;
  max-width: 300px;
  .textfield .input{
    padding: 0.5em 0.6em;
    .input-adornment--end{
      height: 48px;
      width: 3em;
      margin-right: -10px;
    }
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
    max-width: none;
    margin-top: 0.5em;
  }
}