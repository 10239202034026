//Branded Table Styles
.table-key{
  box-shadow: none;
  padding: #{$spacing-unit}px #{$spacing-unit * 3.5}px;
  border-width: 1px;
  border-style: solid;
  text-align: left;
  font-size: $typography-validation;
  font-weight: $font-weight-regular;
  margin: 0 16px 0.5em 0 !important;
  background-color: $aqua-quaternary;
  border-color: $aqua-primary;
  position: relative;

  span {
    background: black;
    color: white;
    display: inline-block;
    min-width: 1rem;
    text-align: center;
    margin-left: -1.125rem;
    margin-right: 0.25rem;
  }

  @include media-breakpoint-down(md){
    padding: 6px 10px;
    font-size: 0.65rem;
    margin-right: 10px !important;
  }
  &--tablestyle1{
    background-color: $stann-quaternary;
    color: $stann-tertiary;
    border-color: $stann-tertiary;
  }

  &--tablestyle2{
    background-color: $ladies-quaternary;
    color: $ladies-tertiary;
    border-color: $ladies-tertiary;

  }

  &--tablestyle3{
    background-color: $green-quaternary;
    color: $green-tertiary;
    border-color: $green-tertiary;
  }

  &--tablestyle4{
    background-color: $orange-quaternary;
    color: $orange-tertiary;
    border-color: $orange-tertiary;
  }

  &--tablestyle5{
    background-color:$parsons-secondary;
    color: $parsons-primary;
    border-color: $parsons-primary;
  }

  &--tablestyle6{
    background-color: $tender-secondary;
    color: $tender-primary;
    border-color: $tender-primary;
  }

  &--tablestyle7{
    background-color: $easy-secondary;
    color: $easy-primary;
    border-color: $easy-primary;
  }
}

.attention-required{
  .attention{
    width: 16px;
    height: 16px;
    border-radius: 25px;
    background-color: $city-primary;
    position: absolute;
    left: -0.65em;
    @include media-breakpoint-down(sm){
      position: inherit;
      margin-bottom: 0.5em;
    }
  }
  p{
    margin-left: 0.5em;
    font-size: $typography-validation;
  }

  &--key{
    display: flex;
    align-items: center;
    margin-left: 0.5em;
    .attention{
      position: inherit;
    }
  }
}

.key-heading{
  font-size: 0.825rem;
  font-weight: 500;
  margin: 0;
}
