.validation {
	display: flex;
	flex-direction: column;
	border-width: 1px;
	border-style: solid;
	border-radius: 5px;
	width: 100%;

	.target {
		padding: #{$spacing-unit}px #{$spacing-unit * 1.5}px;
		margin-bottom: -1px;
	}

	.label {
		font-size: $typography-validation;
		padding: #{$spacing-unit}px #{$spacing-unit * 1.5}px;
	}

	&--error {
		border-color: $error-foreground;
		> .label {
			color: $error-foreground;
		}
	}

	&--suggested {
		border-color: $warning-foreground;
		> .label {
			color: $warning-foreground;
		}
	}

	&--optional {
		border-color: $good-foreground;
		> .label {
			color: $good-foreground;
		}
	}
}
