.star-draw-container {
    padding: 1em;
    width: 400px;
    max-width: 100vw;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
        li {
            margin-top: 0.5em;
        }
    }
}

.workspace-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    h2 {
        color: $denim-primary;
        font-weight: $font-weight-regular;
    }
    border-bottom: 1px solid #e6e6e6;
}

.workqueue-page.draggable-root {
    pointer-events: none;
    .react-draggable {
        pointer-events: auto;
    }
}
