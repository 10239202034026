.popper {
    > div {
        color:$white;
        background-color:$dark-grey;
    }
    &--error {
        > div {
            color:$white;
            background-color:$red-tertiary;
        }
    }
}