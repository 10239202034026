.metrics-page {
    @import '../../../css/breakpoints';
    @import '../../../css/theme';
    @import '../../../css/typography';
    @import '../../../css/components/flex';
    @import '../../../css/components/inline';
    @import '../../../css/components/icon';
    @import 'metricDashboard';
    @import 'metricCard';
    @import 'graphBarChart';
    @import 'ratingBar';
    @import 'dataHighlight';
    @import 'starRating';
    @import 'scorePill';
    @import 'stats';
    @import 'metricContent';
}
