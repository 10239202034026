.upload-field{
  border: 1px solid $grey-secondary;
  background: $white;
  border-radius: $border-radius;
  padding: #{$spacing-unit * 2}px;
  position: relative;

  .label{
    font-weight: $font-weight-light;
    font-size: $typography-body-size;
  }

  .icon{
    width: 1.5rem;
    height: 1.5rem;
    color: $dark-grey;
  }

  input[type=file]{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    &:hover{
      cursor: pointer;
    }
  }
  .inline{
    flex-wrap: nowrap;
    @include media-breakpoint-down(xs){
      flex-wrap: wrap;
      > :not(:first-child) {
        margin-left: 0;
        margin-top: 1em;
      }
    }
  }
}

.attach{
  label{
    margin-bottom:1em;
  }

  margin-bottom:1em;
}