.anonymous {
	display: flex;
	height: 100vh;
	flex-direction: column;

	@include media-breakpoint-up(md) {
		flex-direction: row;
	}

	.side {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		&--left {
			background-color: $app-active;
			flex: 1 1 33%;
			@include media-breakpoint-up(md) {
				flex: 1 1 50%;
			}
		}

		&--right {
			background-color: $app-background-secondary;
			flex: 1 1 67%;
			@include media-breakpoint-up(md) {
				flex: 1 1 50%;
			}
		}
	}

	.logo {
		width: 128px;
		margin-bottom: #{$spacing-unit *4}px;
	}

	.title {
		color: $white;
		font-weight: $font-weight-light;
		font-size: 2.375em;
	}

	.divider-line {
		width: 50;
		height: 4;
		background-color: $app-active;
		margin: 16px 0;
	}
}
