//Score Pills Component
.score-pill {
    background: $white;
    width: 100%;
    padding: #{$spacing-unit * 2}px #{$spacing-unit * 5}px;
    border-radius: #{$border-radius * 20}px;
    display: flex;
    justify-content: space-between;
    text-transform: capitalize;
    .content {
        color: $great;
        font-weight: $font-weight-bold;
        font-size: $typography-display1;
        letter-spacing: 1px;
        margin: 0;
        &--orange {
            color: $bad;
        }

        &--green {
            color: $good;
        }

        &--yellow {
            color: $ok;
        }

        &--red {
            color: $fail;
        }

        @include media-breakpoint-down(md) {
            font-size: $font-size-md;
        }

        @include media-breakpoint-down(sm) {
            font-size: $typography-display1;
        }
    }

    @include media-breakpoint-down(md) {
        padding: #{$spacing-unit * 2}px #{$spacing-unit * 2.5}px;
    }

    @include media-breakpoint-down(sm) {
        padding: #{$spacing-unit * 2}px #{$spacing-unit * 5}px;
    }
}
