.form-label {
	white-space: pre-line;
	font-size: $font-size-reg;
	font-weight: $font-weight-medium;
	font-family: $font;
	color: $black;
	line-height: 1;
	display: inline-block;
	span{
		font-size: $font-size-reg;
	}
}


