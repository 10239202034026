.wq-page {
    padding: 16px;
    @include media-breakpoint-down(lg) {
        padding: 2px;
    }

    //Work Queue Styles
    .wq-header {
        .inline {
            .breadcrumb {
                display: block;
            }
        }

        .wq-header-desk {
            padding-bottom: 16px;
            //@media screen and (max-width: 2000px) {
            //    display: none;
            //}
        }

        .wq-header-mobile {
            margin-top: 0.5em;
            margin-bottom: 1em;
            //@media screen and (min-width: 2000px) {
            //    display: none;
            //}
            .inline-right-align-siblings {
                > :not(:first-child) {
                    margin-left: 0.75em;
                }
            }
        }
    }

    .wq-panels {
        width: 80%;
        height: 100%;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 1660px) {
            width: 73%;
        }
        @include media-breakpoint-down(lg) {
            width: 100%;
        }
    }

    .scrolling-container {
        height: 100%;
        overflow-x: hidden;
        padding: 1em;
        position: relative;
    }

    .task-list-container {
        background: $white;
    }

    .task-list-header {
        padding: 1em;
        box-shadow: 0px 5px 10px -11px rgba(92, 92, 92, 1);

        > .h2.title {
            font-size: 1.6rem;
        }

        .title.wq {
            font-size: 1.125rem;
        }

        .dropdown {
            @include media-breakpoint-down(xs) {
                .sort-by-container select,
                .sort-by-container .select {
                    min-width: 30px;
                }
            }
        }

        .inline {
            @include media-breakpoint-down(xs) {
                flex-wrap: nowrap;
                > :not(:first-child) {
                    margin-left: 1em;
                }
                > * {
                    width: 100%;
                }
            }
        }

        .filter-button {
            @media screen and (max-width: 2000px) {
                display: none;
            }
        }

        .mobile-filter {
            @media screen and (min-width: 2000px) {
                display: none;
            }
        }

        .mobile-search {
            margin-right: 10px;
            @include media-breakpoint-up(sm) {
                display: none;
            }
        }

        .task-desktop-search {
            @include media-breakpoint-down(xs) {
                display: none;
            }
        }
    }

    .task-list {
        flex-grow: 1;
        width: 50%;
        //padding: 1em;
        border: 1px solid $light-grey;
        border-right: none;
        position: relative;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .top-button {
            position: absolute;
            right: 2em;
            bottom: 2em;
            z-index: 1;
            animation: button-fade 2s;
        }

        .list-item-container {
            //padding-right: 1.5em;
            @include media-breakpoint-down(md) {
                max-height: none;
                padding-right: 0;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
            }

            .list-item {
                .task-card {
                    width: 100%;
                    border-bottom: 1px solid $grey-primary;
                    position: relative;
                    margin-bottom: 2px;
                    .text-section * {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &.list {
                        cursor: pointer;
                    }

                    &:hover {
                        filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.1));
                    }

                    /** Tick Icon **/
                    .fab.check {
                        display: none;
                    }
                }
                    &.selected .task-card {
                        border-left: 5px solid $denim-primary;
                        filter: drop-shadow(2px 0 5px rgba(205, 205, 205, 0.7));
                        padding-left: 11px;
                        background: $white;
                        &:after {
                            content: '';
                            width: 0;
                            height: 0;
                            display: block;
                            position: absolute;
                            z-index: 10;
                            border: 0;
                            border-top: 15px solid transparent;
                            border-bottom: 15px solid transparent;
                            margin-top: -15px;
                            top: 50%;
                            border-left: 15px solid $denim-primary;
                            right: auto;
                            left: 0px;
                            filter: drop-shadow(0 2px 8px rgba(0, 0, 0, 0.1));
                        }

                }
            }
        }
    }

    .tag-list {
        display: flex;
        flex-wrap: wrap;
        .tag.button-base {
            margin-right: 3px;
        }
    }

    .task-status {
        font-size: 0.875rem;
        font-weight: bold;
        letter-spacing: 1px;
        margin-left: 0;
        color: $progress-blue;
        @include media-breakpoint-down(sm) {
            .status-label {
                display: none;
            }
        }

        &::before {
            width: 10px;
            height: 10px;
            border-radius: 6px;
            content: '';
            display: inline-block;
            background: $progress-blue;
            margin-right: 0.5em;
            @include media-breakpoint-down(sm) {
                margin-right: 0;
            }
        }

        &.new {
            color: $green-primary;

            &::before {
                background: $green-primary;
            }
        }

        &.overdue {
            color: $red-primary;

            &:before {
                background: $red-primary;
            }
        }

        &.urgent {
            color: $orange-tertiary;

            &:before {
                background: $orange-tertiary;
            }
        }

        &.due {
            color: $mustard-primary;

            &:before {
                background: $mustard-primary;
            }
        }

        &.done {
            color: $denim-secondary;

            &:before {
                background: $denim-secondary;
            }
        }
    }

    .left-margin {
        margin-left: 1.125em;
    }

    /** View Task Header & Footer **/

    .task-header {
        padding: 1em 1em 0 1em;
        vertical-align: middle;

        .due-date {
            font-size: 1.25em;
            margin: auto;
            padding-right: 0.75em;
            color: $almost-black;
            &.overdue {
                color: $red-primary;
            }
        }
      @include media-breakpoint-down(sm){
        padding: 0;
      }
    button {

            svg {
                font-size: 1.25em;
            }

            &.task-star {
                color: $star-yellow;
            }

            &.edit-task {
                color: $denim-primary;
            }

            &.repeat-task {
       // color: $aqua-primary;
            }
        }
    }

    .details-header-desktop {
        @include media-breakpoint-down(xs) {
            display: none;
        }
    }

    .details-header-mobile {
        width: 100%;
        @include media-breakpoint-up(sm) {
            display: none;
        }

        button {
            padding: #{$spacing-unit}px;
            margin-left: 0;
        }
        .task-header {
            border-bottom: 0.5px $grey-primary solid;
            padding: 0.5em;
        }

        .record-id {
            font-weight: lighter;
            font-size: $font-size-sm;
            line-height: 1;
            margin-bottom: 0;
            @include media-breakpoint-down(sm) {
                display: block;
                font-size: 1.1rem;
            }
        }

        .task-status {
            font-size: 1rem;
            .status-label {
                display: inline-block;
            }
        }

        .due-date {
            font-size: $font-size-reg;
        }

        .back-arrow {
            font-size: 0.8em !important;
        }

        &.alerts {
            padding-bottom: 0.5em;
        }
    }

    .task-footer {
        text-align: right;
        position: absolute;
        background-color: $white;
        width: 100%;
        bottom: 0;
        left: 0;
        border-top: 1px solid $light-grey;
        padding: 0.5em;
    }

    /** View Task - WQ Page Only **/

    .view-task-container {
        flex-grow: 1;
        width: 50%;
        border: 1px solid $light-grey;
        position: relative;
        padding-bottom: 1em;
        background: white;
        display: flex;
        flex-direction: column;
        height: 100%;
        @include media-breakpoint-down(md) {
            position: absolute;
            z-index: 1;
            right: 0;
            box-shadow: -2px 0px 6px rgba(0, 0, 0, 0.1);
            height: auto;
            width: 600px;
      max-height: calc(100% - 90px);
            max-width: 100vw;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            max-height: calc(100% - -1px);
            top: -1px;
            left: 0;
            position:fixed;
            z-index: $z-index-modal;
        }

        @include media-breakpoint-down(xs) {
            top: -2px;
            max-height: calc(100% - -2px);
        }

        .top-button {
            position: absolute;
            right: 2em;
            bottom: 5.5em;
            z-index: 1;
            animation: button-fade 2s;
        }
        .scrolling-container {
            margin-bottom: 54px;
        }
        .task-body {
            .task-card {
                width: 100%;
                border: none;
                padding-bottom: 80px;

                .record-id {
                    font-size: $font-size-sm;
                    line-height: 1;
                    @include media-breakpoint-down(sm) {
                        display: block;
                    }
                }

                h2{
                    color: $denim-primary;
                    font-weight: $font-weight-medium;
                    font-size: 1.5em;
                    &.spacing {
                        margin-top: 0;
                        margin-bottom: 0.8em;
                    }
                }

                h3 {
                    letter-spacing: 0.01em;
                    color: $almost-black;

                    &.spacing {
                        margin-top: 0.8em;
                        margin-bottom: 6px;
                    }
                }

                p {
                    font-weight: $font-weight-light;
                }

                .form-label {
                    color: #2f2f2f;
                }

                .task-heading {
                    color: $denim-primary;
                    margin: 0;
                    font-size: $font-size-xxlg;
                    font-weight: $font-weight-medium;
                    letter-spacing: normal;
                    padding: 0.3em 0;
                    line-height: 1.5;
                }

                .task-description {
                    font-size: 1.2rem;
                    font-weight: lighter;
                    line-height: 1.4;
                    white-space: pre-wrap;
                }

                .task-assignments {
                    margin-top: 0.8em;
                }
                .task-message {
                    margin-bottom: 0.8em;
                    p {
                        font-weight: lighter;
                    }
                    p.quoted {
                        white-space: pre-wrap;
                        font-style: italic;
                        quotes: '“' '”' '‘' '’';
                        position: relative;
                        padding: 0 0 6px 28px;
                    }
                    p.quoted:before {
                        content: open-quote;
                        left: 0;
                        top: 0;
                    }
                    p.quoted:after {
                        content: close-quote;
                    }
                    p.quoted:before,
                    p.quoted:after {
                        font-size: 3.25em;
                        position: absolute;
                        line-height: 1;
                        font-family: Georgia, serif;
                        color: #3334;
                    }
                }
            }
        }
    }

    @keyframes button-fade {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}

.popover--search {
    width: 100%;
    .search-field {
        margin-top: 0;
    }
}
