h3.metrics {
    font-weight: $font-weight-regular;
    font-size: $font-size-sm;
    margin-top: 0;
    letter-spacing: 0;
    text-transform: capitalize;
}

h2 {
    margin: #{$spacing-unit * 5}px 0 0;
    font-weight: $font-weight-light;
    text-transform: capitalize;
}

/** Admin Performance Pane **/

.highlight-container {
    height: 100%;
    .metric-card {
        height: 49%;
        margin-bottom: 2%;
    }
    .metric-card:last-child {
        margin-bottom: 0;
    }
}
