/* do CM related style updates */

.rbc-calendar {
    h1 {
        color: #7d9cc4;
        white-space: nowrap;
        display: inline-block;
    }
    .rbc-toolbar {
        .rbc-btn-group button.rbc-active {
            background: #48c3d1;
        }
        .rbc-btn-group .filterBtn {
            background: #ffba81;
            border-radius: 32px;
            padding: 8px 28px !important;
            svg{
                margin-right: 12px;
            }
            &:hover{
                background-color: #e87b28 !important;
            }
            &.filterBtnOff{
                background: #48c3d1 !important;
                color: #ffffff !important;
                border: transparent;
                &:hover{
                    background-color: #399ea5 !important;
                    border-color: #399ea5;
                }
            }
        }
        button,
        button:hover {
            color: #333;
        }
        .flex-container {
            span:first-child {
                padding: 6px 10px 6px 0px;
            }
        }
    }
    .title {
        color: unset;
    }
}
