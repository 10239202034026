.alert-bar {
    display: flex;
    align-items: center;
    border-width: 1.3px;
    border-style: solid;
    border-radius: #{$border-radius}px;
    font-size: $font-size-xs;
    padding: #{$spacing-unit}px #{$spacing-unit}px;
    width: 100%;
	margin: 1em 0;

    .content {
        margin-left: #{$spacing-unit}px;
		width: 100%;
    }

    &--success {
		border-color: $good-foreground;
		background: $good-background;
		> svg {
			color: $good-foreground;
		}
    }
    &--error {
		border-color: $error-foreground;
		background: $error-background;
		> svg {
			color: $error-foreground;
		}
    }
    &--warning {
		border-color: $warning-foreground;
		background: $warning-background;
		> svg {
			color: $warning-foreground;
		}
    }
    &--info {
		border-color: $info-foreground;
		background: $info-background;
		> svg {
			color: $info-foreground;
		}
    }
	&--attention {
		border-color: $denim-tertiary;
		background: rgba(90, 118, 155, 0.1);
		> svg {
			color: $denim-tertiary;
		}
	}

	&--fire {
		border-color: $fire-border;
		background: $fire-background;
		> svg {
			color: $fire-border;
		}
	}

	.alert-bar-button{
		padding: 0;
	}
}