.radio-group {
	//flex-direction: row;
	//align-items: center;

.control {
		display: flex;
		flex-direction: column;
	label > span {
		padding-right: 6px;
	}
	}

	.validation-label {
		&.error {
			color: $error-foreground;
		}
		&.suggested {
			color: $warning-foreground;
		}
		&.optional {
			color: $good-foreground;
		}
		margin-top: 12px;
	}

	&:not(.disabled) .checked {
		input, svg {
			color:$aqua-primary;
		}
	}

	&.error,
	&.suggested,
	&.optional {
		border-width: 1px;
		border-radius: #{$border-radius}px;
		border-style: solid;
		padding: #{$spacing-unit}px;
	}

	&.error {
		border-color: $error-foreground;
		color: $error-foreground;
	}
	&.suggested {
		border-color: $warning-foreground;
		color: $warning-foreground;
	}
	&.optional {
		border-color: $good-foreground;
		color: $good-foreground;
	}
}
