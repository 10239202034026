.column-layout {
	flex-grow: 1;
	display: flex;
	flex-wrap: wrap;

	> div.child {

		width:100%;
		
		@include media-breakpoint-up(lg){
			&.col{
				&--1 {
					width: 100%;
				}
				&--2 {
					width: 50%;
				}
				&--3 {
					width: 33.33%;
				}
				&--4 {
					width: 25%;
				}
				&--5 {
					width: 20%;
				}
				&--6 {
					width: 16.66%;
				}
				&--7 {
					width: 14.2857%;
				}
				&--8 {
					width: 12.5%;
				}
				&--9 {
					width: 11.11%;
				}
				&--10 {
					width: 10%;
				}
				&--11 {
					width: 9.0909%;
				}
				&--12 {
					width: 8.33%;
				}
			}
		}
	}

	//give nested children some padding
	> div.child.pad > :not(:first-child) {
		margin-top: #{$spacing-unit}px
	}

	@include media-breakpoint-up(lg) {
		> div.child:first-child {
			padding-right: #{$spacing-unit * 1.5}px;
		}

		> div.child:not(:first-child) {
			padding-left: #{$spacing-unit * 1.5}px;
			border-left: 1px solid $light-grey;
		}
	}

	@include media-breakpoint-down(lg) {
		> div.child:first-child {
			padding-bottom: #{$spacing-unit * 1.5}px;
		}

		> div.child:not(:first-child) {
			padding-top: #{$spacing-unit * 1.5}px;
			border-top: 1px solid $light-grey;
		}
	}
}
