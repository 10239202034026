.colour-block {
	display: flex;
	flex-direction: column;

	.block-container {
		display: flex;

		.block {
			height: 30px;
			width: 120px;
			text-transform: capitalize;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		> :not(:first-child) {
			//margin-left: #{$spacing-unit * 2}px;
		}
	}

	.label {
		margin-bottom: #{$spacing-unit}px;
	}
}
