//Graph
.chart-container{
  text-align: center;
  .bar{
    height: 20em;
    width: 100%;
    .bar-track{
      position: relative;
      height: 100%;
      background: #E5E5E5;
      .bar-fill{
        position: absolute;
        bottom: 0;
        width: 100%;
        background: $great;
        text-align: center;
        animation: fill-bar 2s ease-in-out;
        display: flex;
        justify-content: center;

        .stats-container{
          position: absolute;
          top: -0.85rem;
          background: $white;
          padding: #{$spacing-unit * 0.5}px #{$spacing-unit * 1}px;
          -webkit-box-shadow: 0px 0px 11px -2px rgba(97,97,97,0.4);
          -moz-box-shadow: 0px 0px 11px -2px rgba(97,97,97,0.4);
          box-shadow: 0px 0px 11px -2px rgba(97,97,97,0.4);
          border-radius: #{$border-radius * 20}px;
          span{
            font-size: $font-size-reg;
            color: $great;
            font-weight: $font-weight-bold;
          }
        }

        &--orange {
          background: $bad;
          .stats-container{
            span{
              color: $bad;
            }
          }
        }

        &--green{
          background: $good;
          .stats-container{
            span{
              color: $good;
            }
          }
        }

        &--yellow{
          background: $ok;
          .stats-container{
            span{
              color: $metric-grey;
            }
          }
        }

        &--red{
          background: $fail;
          .stats-container{
            span{
              color: $fail;
            }
          }
        }
      }
    }
  }

  .horizontal-number{
    height: 2.5em;
  }

  .label-container{
    margin-top: 1em;
    height: 2.5em;
    margin-bottom: 0.3em;
    .chart-label{
      font-size: $font-size-reg;
      word-break: break-word;
    }
  }

  .stackedBar{
    background: #E5E5E5;
    display: flex;
    flex-direction: column;
    height: inherit;
    overflow:hidden;
    justify-content: flex-end;

    .bar-fill {
      display: flex;
      align-items: center;
      justify-content: center;
      animation: fill-bar 2s ease-in-out;
      background: $great;
      &.orange {
        background: $bad;
        .percent-text{
          color: $bad;
        }
      }

      &.green{
        background: $good;
        .percent-text{
          color: $good;
        }
      }

      &.yellow{
        background: $ok;
        .percent-text{
          color: $metric-grey;
        }
      }

      &.red{
        background: $fail;
        .percent-text{
          color: $fail;
        }
      }
    }

    .percent-text {
      color: $great;
      font-size: 0.975rem;
      font-weight: 700;
      text-align: center;
      background: #ffffff;
      padding: 4px 8px;
      box-shadow: 0px 0px 11px -2px rgba(97, 97, 97, 0.4);
      border-radius: 60px;
    }
  }


}

@keyframes fill-bar{
  0%{height:0;}
}

.graph-title{
  padding-bottom: #{$spacing-unit * 2}px;
}

.vertical-number{
  width: 3%;
  height: 20em;
  margin-right: 1%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  align-content: stretch;
  margin-top: 2em;
}

.two{
  .chart-container{
    width: 47%;
    margin-right: 6%;
    &:last-child {
      margin-right: 0;
    }
  }
}

.three{
  .chart-container{
    width: 31%;
    margin-right: 3.5%;
    &:last-child{
      margin-right: 0;
    }
  }
}

.four{
  .chart-container{
    width: 23%;
    margin-right: 2.5%;
    &:last-child{
      margin-right: 0;
    }
  }
}
