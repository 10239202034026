.task-actions{
  margin: 1em 0;
  .task-notes{
    label{
      margin-bottom:1em;
    }
  }

  .assigned-staff{
    .left-label .field-container > .user-circle:not(:first-child) {
      margin-top: 1em;
    }
  }

  .file-list{
    h4{
      margin-top: 1em;
      color: #2f2f2f;
      font-size: $font-size-reg;
      font-weight: $font-weight-medium;
    }
  }

}
