//Star Rating
.star-rating-component{
  background: $great;
  min-height: 15rem;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 0;
  margin-bottom: 4rem;

  p {
    margin: #{$spacing-unit - 2}px auto;
  }

  &--orange {
    background: $bad;
    .data-container{
      p.data{
        color: $bad;
      }
    }
  }

  &--green{
    background: $good;
    .data-container{
      p.data{
        color: $good;
      }
    }
  }

  &--yellow{
    background: $ok;
    .data-container{
      p.data{
        color: $metric-grey;
      }
    }
  }

  &--red{
    background: $fail;
    .data-container{
      p.data{
        color: $fail;
      }
    }
  }
  .data-container{
    padding: #{$spacing-unit * 1}px #{$spacing-unit * 2}px;
    display: flex;
    background: $white;
    margin: 0 auto;
    -webkit-box-shadow: 0px 0px 11px -2px rgba(97,97,97,0.4);
    -moz-box-shadow: 0px 0px 11px -2px rgba(97,97,97,0.4);
    box-shadow: 0px 0px 11px -2px rgba(97,97,97,0.4);
    border-radius: #{$border-radius * 20}px;
    .data {
      font-weight: $font-weight-bold;
      font-size: $typography-display2;
      letter-spacing: 1px;
      color: $great;
    }
  }

  .star-position{
    position: absolute;
    bottom: -2rem;
    width: 100%;
  }

  .star-rating-container{
    display: flex;
    -webkit-box-shadow: 0px -6px 8px -6px rgba(97,97,97,0.40);
    -moz-box-shadow: 0px -6px 8px -6px rgba(97,97,97,0.40);
    box-shadow: 0px -6px 8px -6px rgba(97,97,97,0.40);
    border-radius: #{$border-radius * 20}px;
    width:250px;
    margin:0 auto;
    @include media-breakpoint-down(md){
       width: 180px;
    }
    @include media-breakpoint-down(sm){
      width: 250px;
    }
    .number{
      display: flex;
      align-items: center;
      background: $metric-grey;
      font-weight: $font-weight-light;
      color: $white;
      width: 25%;
      text-align: center;
      padding: #{$spacing-unit * 2}px;
      border-top-left-radius: #{$border-radius * 20}px;
      border-bottom-left-radius: #{$border-radius * 20}px;
      -webkit-box-shadow: 10px -1px 20px -11px rgba(97,97,97,0.55);
      -moz-box-shadow: 10px -1px 20px -11px rgba(97,97,97,0.55);
      box-shadow: 10px -1px 20px -11px rgba(97,97,97,0.55);
      z-index: 2;
      p{
        font-size: $font-size-sm;
      }
    }
    .container{
      background: $white;
      width: 75%;
      padding: #{$spacing-unit * 1}px;
      border-top-right-radius: #{$border-radius * 20}px;
      border-bottom-right-radius: #{$border-radius * 20}px;
      z-index: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      @include media-breakpoint-down(md){
        padding: 13px 8px;
      }
      @include media-breakpoint-down(sm){
        padding: 8px;
      }
      .star-container:last-of-type{
        padding-left: 2em;
      }
    }
  }
}

.star-container{
  margin-right: 2em;
  .back-stars{
    display: flex;
    position: relative;
    .star-icon{
      font-size: 1.6em;
      @include media-breakpoint-down(md){
        font-size:1.15rem;
      }
      @include media-breakpoint-down(sm){
        font-size: 1.6em;
      }
      &.grey{
        color:$grey-primary;
      }
    }
    .front-stars{
      display: flex;
      overflow: hidden;
      position: absolute;
      top: 0;
      transition: all 0.5s;
      width: 100%;
      animation: star-rating 2s ease-in-out;
    }
  }
}

@keyframes star-rating{
  0%{width:0;}
}
