.metric-card {
  background: white;
  width: 100%;
  height:100%;
  padding: 1rem 2rem;
  border-bottom: 5px solid $great;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  &--orange {
    border-bottom: 5px solid $bad;
  }

  &--green{
    border-bottom: 5px solid $good;
  }

  &--yellow{
    border-bottom: 5px solid $ok;
  }
  &--red{
    border-bottom: 5px solid $fail;
  }
  &--clear{
    border-bottom-color: transparent;
    background: none
  }

  &.height-auto{
    height: auto;
  }

  h2 {
    line-height: 1.5;
    margin-top: 0.75rem;
    margin-bottom: 1rem;
  }

  &::before, &::after{
    content: "";
    flex: 1;
  }
  @include media-breakpoint-down(md){
    padding: 1rem 1rem;
  }
}
