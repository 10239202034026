@import './breakpoints.scss';
@import './theme.scss';
@import './typography.scss';
@import './components/flex.scss';
@import './components/card.scss';
@import './components/addressAutocomplete.scss';
@import './components/appointment.scss';
@import './components/alertBar.scss';
@import './components/autocomplete.scss';
@import './components/button.scss';
@import './components/checkbox.scss';
@import './components/columnLayout.scss';
@import './components/colourBlock.scss';
@import './components/common.scss';
@import './components/dataTable.scss';
@import './components/drawer.scss';
@import './components/fileThumbnailCard.scss';
@import './components/footer.scss';
@import './components/gqlAutocomplete.scss';
@import './components/grid.scss';
@import './components/header.scss';
@import './components/icon.scss';
@import './components/imageUploadedCard.scss';
@import './components/inline.scss';
@import './components/label.scss';
@import './components/linearProgressIndicator.scss';
@import './components/modal.scss';
@import './components/notificationPopover.scss';
@import './components/paper.scss';
@import './components/radioGroup.scss';
@import './components/searchWithButton.scss';
@import './components/select.scss';
@import './components/separator.scss';
@import './components/snackbar.scss';
@import './components/sortByDropdown.scss';
@import './components/leftLabel.scss';
@import './components/spinner.scss';
@import './components/stepper.scss';
@import './components/table.scss';
@import './components/clearTable.scss';
@import './components/tableKey';
@import './components/tabs.scss';
@import './components/textfield.scss';
@import './components/tooltip.scss';
@import './components/uploadField.scss';
@import './components/userCircle.scss';
@import './components/validation.scss';
@import './components/notesReadonly.scss';
@import './components/metricsDashoard/metricDashboard';
@import './main.scss';
@import './anonymous.scss';
@import './signIn.scss';
@import './components/breadcrumb.scss';
@import './components/base.scss';
@import './components/starDraw.scss';
@import './components/metricsDashoard/metricCard';
@import './components/metricsDashoard/graphBarChart';
@import './components/metricsDashoard/ratingBar';
@import './components/metricsDashoard/dataHighlight';
@import './components/metricsDashoard/starRating';
@import './components/metricsDashoard/scorePill';
@import './components/metricsDashoard/stats';
@import './components/metricsDashoard/metricContent';
@import "./components/imageDetailsCard";


.app {
	padding-bottom: #{$size-footer-height}px;
	display: flex;
	width: 100%;
	height: 100vh;
	letter-spacing: 0.3px;
}
