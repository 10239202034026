.footer {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	height: #{$size-footer-height}px;
	box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
	border-top: 1px solid $light-grey;
	z-index: $z-index-footer;

	display: flex;
	justify-content: center;
	background-color: $white;

	.footer-item {
		max-width: 100px;
		height: 100%;
		color: $app-active;
		text-decoration: none;

		&:hover {
			background-color: $app-hover;
		}

		&--active {
			background-color: $app-selected;
			border-bottom: 2px solid $app-active;
			border-top: 2px solid transparent;
		}

		.label {
			font-size: $typography-subheading-size;
			margin-top: #{$spacing-unit}px;
			white-space: pre;
		}
	}
}
