.main {
  margin-top: #{$size-header-height}px;
  flex: 1 1 auto;
  overflow-y: auto;
  background-color: $app-background-secondary;
  padding: #{$spacing-unit * 1}px;
  position: relative;

  @include media-breakpoint-down(xs){
    padding: #{$spacing-unit * 1}px;
  }

  .formFooter {
    position: fixed;
    bottom: 1rem;
    right: 3rem;
    z-index: 1202;
  }
}

@media screen and (max-width: 800px) {
  .main .formFooter {
    bottom: calc(1rem + #{$size-footer-height}px);

    > button {
      height: 4rem;
      width: 4rem;

      svg {
        font-size: 2rem;
        margin: 0;
      }

      > span > span {
        display: none
      }
    }
  }
}

.disabled-overlay{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(232, 232, 232, 0.75);
  z-index: 1000;
  background-image: url("./../assets/under-construction.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(lg) {
    background-size: 100%;
    position: fixed;
    background-position: left bottom;
    bottom: 70px;
  }

  @include media-breakpoint-down(sm) {
    background-image: url("./../assets/under-construction-section.svg");
  }

  .message{
    padding-left: 24px;
    z-index: 1001;
    color: $denim-tertiary;
    text-align: center;
    @include media-breakpoint-down(md) {
      padding-left: 0;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    h1{
      font-weight: $font-weight-medium;
      font-size: 3rem;
      margin: 0.25em 0;
    }

    h2{
      font-weight: $font-weight-regular;
      font-size: 1.5rem;
      margin: 0.25em 0;
    }
  }

  &.section{
    background-image: url("./../assets/under-construction-section.svg");
    background-size: 70% 65%;
    background-position: bottom;
    @include media-breakpoint-down(lg) {
      position: absolute;
      background-size: 100%;
      bottom: 0;
    }

    .message{
      width: 100%;
      @include media-breakpoint-down(md) {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
      }
      @include media-breakpoint-down(xs) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      h1{
        font-size: 2rem;
        font-weight: $font-weight-medium;
      }

      h2{
        font-size: 1rem;
      }
    }
  }

  &.feature{
    background-color: rgba(232, 232, 232, 0.75);
    background-image: none;
    @include media-breakpoint-down(lg) {
      position: absolute;
      bottom: 0;
    }
    .message{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      h1{
        font-size: 2rem;
        font-weight: $font-weight-medium;
        text-align: left;
        @include media-breakpoint-down(lg) {
          text-align: center;
        }
      }

      h2{
        font-size: 1rem;
        text-align: left;
        @include media-breakpoint-down(lg) {
          text-align: center;
        }
      }
      .dev-image{
        width: 50%;
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
      .text{
        width: 50%;
        @include media-breakpoint-down(lg) {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
