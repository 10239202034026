.uploaded-image-details{
  width: 49%;
  padding-right: 1%;
  padding-bottom: 1.5em;
  @include media-breakpoint-down(sm){
    width: 100%;
    padding-right: 0;
  }

  .readonly{
    margin-bottom: 1em;
  }

  button.button-base{
    background-color: transparent;
    border: transparent;
    width: 100%;
    padding: 0;
    color: $black;
    text-transform: none;
    border-radius: 0;
    &:hover{
      background-color: transparent;
      color: $black;
    }
    &:active{
      box-shadow: 0px 5px 5px -3px rgba(0,0,0,0)
    }
  }
}