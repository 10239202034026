.table {
  border-spacing: 0;
  border-collapse: separate;
  width: 100%;
  font-size: $typography-validation;

  th {
    border: none;
  }

  td {
    border-style: solid;
    border-width: 1px 0px 0px 1px;
  }

  td:last-child {
    border-width: 1px 1px 0px 1px;
  }

  tr:last-child td {
    border-width: 1px 0px 1px 1px;
  }

  tr:last-child td:last-child {
    border-width: 1px;
  }

  tr {
    &:hover {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    }
  }

  .row {
    &--header {
      outline-color: transparent;
      text-align: left;
      font-weight: $font-weight-medium;
      font-size: $font-size-xs;

      > .td, > td, > th {
        background-color: $table-header-background;
        color: $table-header-foreground;
        border-color: $table-row-border;
        font-size: 0.925rem;
        font-weight: 500;
      }

      > .td:first-child {
        border-top-left-radius: #{$border-radius}px;
      }

      > .td:last-child {
        border-top-right-radius: #{$border-radius}px;
      }

      &:hover {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
      }
    }

    &--header-nested {
      text-align: left;
      font-size: 0.925rem;
      font-weight: $font-weight-medium;

      > .td {
        background-color: $table-header-nested-background;
        color: $table-header-nested-foreground;
        border-color: $table-header-nested-border;
      }

      &:hover {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
      }
    }

    &.total-row-desktop{
      &:hover {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
      }
      td:not(.empty-cell){
       border-bottom: 1px solid $table-row-border;
      }
    }

    &--normal {
      > .td {
        background-color: $table-row-background;
        color: $table-row-foreground;
        border-color: $table-row-border;
        &.table-center{
          text-align: center;
        }

        &.empty-cell {
          border-color: transparent;
          background-color: transparent;
          border-top-color: $table-row-border;
        }

        &.total-cell {
          text-align: right;
          font-weight: $font-weight-medium;
          font-size: $font-size-xs;
          background-color: $table-header-background;
          color: $table-header-foreground;
        }
      }

      &:hover > .td {
        background-color: $table-row-hover;

        &.empty-cell {
          background-color: transparent;
          cursor: auto;
        }

        &.total-cell {
          background-color: $table-header-background;
        }
      }
    }

    &--nested {
      > .td {
        background-color: $table-row-nested-background;
        color: $table-row-nested-foreground;
        border-color: $table-row-nested-border;
      }

      &:hover > .td {
        background-color: $table-row-nested-hover;

      }
    }

    &--padded {
      > .td {
        padding: #{$spacing-unit}px;
      }
    }

    .td {
      &:not(:first-child) {
        border-left-style: dotted;
        @media (max-width: 760px) {
          border-left-style: hidden;
        }
      }

      &--padded {
        padding: #{$spacing-unit}px;
      }
    }

    //Responsive table styles
    @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
      border: 2px solid $table-row-border;
      margin: 0 0 1rem 0;

      &:nth-child(odd) {
        //border: 1.5px solid $denim-primary;
      }

      /* Force table to not be like tables anymore */
      table, thead, tbody, th, td, tr {
        display: block;
      }

      /* Hide table headers (but not display: none;, for accessibility) */
      &--header {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      .td {
        /* Behave  like a "row" */
        border: none;
        position: relative;
        padding-left: 50%;
        min-height: 35px;
        width: 100%;
        &.table-center{
          text-align: left;
        }
      }

      .td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 0;
        left: 0;
        width: 47%;
        padding-right: 0px;
        word-break: break-word;
        font-weight: $font-weight-medium;
        font-size: $font-size-xs;
        color: $table-header-foreground;
        height: 100%;
        padding-left: 6px;
        padding-top: 6px;
        background-color: $table-header-background;
        border-bottom: 1px dotted $white;
        text-align: left;
        content: attr(data-label);
      }

      .td:after{
        border-bottom: 1px dotted $table-row-border;
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 53%;
        padding-left: 6px;
        padding-top: 6px;
      }

      &--header-nested {
        border: 0;
        margin-bottom: 0;
        .td{
          padding: #{$spacing-unit}px;
          &:before{
            display: none;
          }
          &:after{
            display: none;
          }
        }
      }

      //Branded Table Styles
      //&--tablestyle1 {
      //  > .td:after {
      //    border-color: $stann-quaternary;
      //  }
      //}
      //
      //&--tablestyle2 {
      //  > .td:after {
      //    border-color: $ladies-tertiary;
      //  }
      //}
      //
      //&--tablestyle3 {
      //  > .td:after {
      //    border-color: $green-tertiary;
      //  }
      //}
      //
      //&--tablestyle4 {
      //  > .td:after {
      //    border-color: $orange-tertiary;
      //  }
      //}
      //
      //&--tablestyle5 {
      //  > .td:after {
      //    border-color: $parsons-primary;
      //  }
      //}
      //
      //&--tablestyle6 {
      //  > .td:after {
      //    border-color: $tender-primary;
      //  }
      //}
      //
      //&--tablestyle7 {
      //  > .td:after {
      //    border-color: $easy-primary;
      //  }
      //}
      //
      //.td:last-child{
      //  &:after, &:before {
      //    border-bottom: none;
      //  }
      //}
    }

    //Branded Table Styles
    //&--tablestyle1 {
    //  outline-color: $stann-tertiary;
    //  > .td {
    //    background-color: $stann-quaternary;
    //    svg, a, a > span {color: $stann-tertiary;}
    //    border-color: $stann-tertiary;
    //  }
    //
    //  &:hover > .td {
    //    background-color: $stann-quaternary;
    //  }
    //}
    //
    //&--tablestyle2 {
    //  outline-color: $ladies-tertiary;
    //  > .td {
    //    background-color: $ladies-quaternary;
    //    svg, a, a > span {color: $ladies-tertiary;}
    //    border-color: $ladies-tertiary;
    //  }
    //
    //  &:hover > .td {
    //    background-color: $ladies-quaternary;
    //  }
    //}
    //
    //&--tablestyle3 {
    //  outline-color: $green-tertiary;
    //  > .td {
    //    background-color: $green-quaternary;
    //    svg, a, a > span {color: $green-tertiary;}
    //    border-color: $green-tertiary;
    //  }
    //
    //  &:hover > .td {
    //    background-color: $green-quaternary;
    //  }
    //}
    //
    //&--tablestyle4 {
    //  outline-color: $orange-tertiary;
    //  > .td {
    //    background-color: $orange-quaternary;
    //    svg, a, a > span {color: $orange-tertiary;}
    //    border-color: $orange-tertiary;
    //  }
    //
    //  &:hover > .td {
    //    background-color: $orange-quaternary;
    //  }
    //}
    //
    //&--tablestyle5 {
    //  outline-color: $parsons-primary;
    //  > .td {
    //    background-color: $parsons-secondary;
    //    svg, a, a > span {color: $parsons-primary;}
    //    border-color: $parsons-primary;
    //  }
    //
    //  &:hover > .td {
    //    background-color: $parsons-secondary;
    //  }
    //}
    //
    //&--tablestyle6 {
    //  outline-color: $tender-primary;
    //  > .td {
    //    background-color: $tender-secondary;
    //    svg, a, a > span {color: $tender-primary;}
    //    border-color: $tender-primary;
    //  }
    //
    //  &:hover > .td {
    //    background-color: $tender-secondary;
    //  }
    //}
    //
    //&--tablestyle7 {
    //  outline-color: $easy-primary;
    //  > .td {
    //    background-color: $easy-secondary;
    //    svg, a, a > span {color: $easy-primary;}
    //    border-color: $easy-primary;
    //  }
    //
    //  &:hover > .td {
    //    background-color: $easy-secondary;
    //  }
    //}

    &:hover {
      outline-style: solid;
      outline-width: 3px;
    }
  }
  td.td.icon {
    text-align: center;
    @include media-breakpoint-up(md) {
      width: 60px;
      padding: 2px;
    }
    > svg {
      font-size: 1.88em;
    }
    > span svg{
      font-size: 1em;
    }
  }

  .task-status{
    @include media-breakpoint-down(sm){
      .status-label{
        display: inline-block;
      }
    }
  }
}

.total-row-mobile {
  display: none;
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table.table {
    display: block;
    thead, tbody, th, td, tr {
      display: block;
    }
  }
  .total-row-desktop {
    display: none;
  }

  .total-row-mobile {
    display: inherit;
  }
}

.table.no-response {
  /* Force table to not be like tables anymore */
  table, thead, tbody, th, td, tr {
    display: grid;
  }

  .row {
    //Removing Responsive table styles
    @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
      border: none;
      margin: 0;

      &--header {
        position: inherit;
        top: 0;
        left: 0;
      }

      .td {
        /* Behave  like a "row" */
        border: 1px solid $table-row-border;
        position: relative;
        padding: #{$spacing-unit}px;
      }

      .td:before {
        display: none;
      }
    }
  }
}

.runsheet{
  margin-bottom:  2.5em;
  h5, p{
    padding-bottom: 0.5em;
  }

  h5{
    font-size: 17px;
    margin-top: 1rem;
    text-transform: uppercase;
  }
  p{
    font-size: 15px;
    display: flex;
    align-items: flex-end;
  }
  .info{
    font-weight: $font-weight-medium;
    width: 130px;
    display: inline-block;
  }

  a:hover{
    color: $dark-grey;
  }

  .runsheet-header{
    text-transform: uppercase;
    font-size: 15px;
  }

  .header-record{
    font-weight: $font-weight-medium;
    font-size: 14px;
    padding-bottom: 0;
  }

  .table {
    td{
      page-break-inside: avoid;
      width: 50%;
      border-width: 1px 0px 1px 1px;
      &:last-child{
        border-right-width: 1px;
      }
    }
    tr:nth-child(3) td:last-child {
      border-width: 1px;
      border-top-width: 0;
    }
    &:hover {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    }
    tr {
      &:nth-child(1):hover {
        box-shadow: red;
      }

      &:hover {
        box-shadow: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        outline: none;
      }
    }

    .row {
      &--header {
        text-align: left;
        font-weight: $font-weight-medium;
        font-size: $font-size-xs;

        > .td {
          background-color: $table-header-background;
          color: $table-header-foreground;
          border-color: $table-row-border;
        }

        td:first-child {
          border-top-left-radius: #{$border-radius}px;
        }

        td:last-child {
          border-top-right-radius: #{$border-radius}px;
        }
      }

      &--normal {
        > .td {
          vertical-align: top;
          background-color: white;
          color: $table-row-foreground;
          border-color: $table-row-border;
        }
      }

      &:hover {
        background-color: #ecf9fa;
      }

      //Removing Responsive table styles
      @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
        border: none;
        margin: 0;

        &--header {
          position: inherit;
          top: 0;
          left: 0;
        }

        .td {
          /* Behave  like a "row" */
          border: 1px solid $table-row-border;
          position: relative;
          padding: #{$spacing-unit}px;
          width: 100%;
        }

        .td:before {
          display: none;
        }
      }
    }
  }

  &--priority{
    .table {
      .row {
        &--header {
          text-align: left;
          font-weight: $font-weight-medium;
          font-size: $font-size-xs;

          > .td {
            background-color: $orange-primary;
            color: $table-header-foreground;
            border-color: $orange-primary;
          }

          td:first-child {
            border-top-left-radius: #{$border-radius}px;
          }

          td:last-child {
            border-top-right-radius: #{$border-radius}px;
          }
        }

        &--normal {
          > .td {
            vertical-align: top;
            background-color: white;
            color: $table-row-foreground;
            border-color: $orange-primary;
          }
        }

        &:hover {
          background-color: $orange-quaternary;
        }
      }
    }
  }
}
