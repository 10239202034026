.spinner {
	&--delay {
		opacity: 0;
		animation: spinner-fade-in 1s linear 1.3s;
		animation-fill-mode: forwards;
	}

	.circle {
		animation: spinner-spin 1s linear infinite;
	}


	&--lg {
		width:64px;
	}
	&--md {
		width:48px;
	}
	&--sm {
		//default size
		width:32px;
	}
	&--xs {
		width:16px;
	}
}

@keyframes spinner-fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes spinner-spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
