.inline{
  .breadcrumb{
    padding: 0 0;
    list-style: none;
    background: none;
    width: 100%;
    margin-left:0;
    font-size: 1rem;
    margin-top:1em;
    display:none;
    li{
      display: inline;
      text-decoration:none;
      font-weight:400;
      color:$black;
      a{
        text-decoration:none;
        font-weight:400;
        color:$black;
        &:hover{
          text-decoration:underline;
        }
      }
    }
  }

  ul.breadcrumb li + li:before {
    padding: 4px;
    content: "/\A0";
  }
}
