// Stats Component
.stats-content{
  width: 32%;
  margin-right: 1%;
  @include media-breakpoint-down(md){
    width: 49%;
  }
  @include media-breakpoint-down(xs){
    width: 100%;
    margin-right: 0;
  }

  h4{
    font-size: $font-size-xs;
    color:$grey-primary;
    font-weight: $font-weight-regular;
  }

  p{
    font-weight: $font-weight-bold;
    font-size: $typography-display2;
    color: $black;
    letter-spacing: 1px;
    margin: 0 0.5em 0 0;
  }

  .arrow{
    font-size: 1.6em;
    display: none;
    &--up{
      color: $good;
      display: flex;
    }

    &--down{
      color: $fail;
      transform: rotate(180deg);
      display: flex;
    }
  }

  .container{
    display: flex;
    align-items: center;
    padding-top: #{$spacing-unit * 2}px;
  }
}