.card {
  background: white;
  width: 100%;
  height:100%;
  padding: 1rem 2rem;
  border-bottom: 5px solid $denim-primary;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  &--orange {
    border-bottom: 5px solid $orange-primary;
  }

  &--green{
    border-bottom: 5px solid #44AF69;
  }

  &--yellow{
    border-bottom: 5px solid #F7DD60;
  }
  &--red{
    border-bottom: 5px solid #D14F57;
  }

  &.height-auto{
    height: auto;
  }

  h2 {
    line-height: 1.5;
    margin-top: 0.75rem;
    margin-bottom: 1rem;
  }

  &::before, &::after{
    content: "";
    flex: 1;
  }
  @include media-breakpoint-down(md){
    padding: 1rem 1rem;
  }
}

.contactcard{
  position: relative;
  display:inline-block;
  background: $white;
  border-radius: #{$border-radius * 3}px;
  padding: #{$spacing-unit * 2}px;
  color: $dark-grey;
  border: 1px solid $light-grey;
  overflow: hidden;
  margin-bottom: 1rem;

  @include media-breakpoint-up(md){
    width: 24.25%;
  }

  @include media-breakpoint-down(md) {
    width: 49%;
  }

  @include media-breakpoint-down(sm) {
    width: 49%;
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
  }
  &--fullwidth{
    width: 100%;
    margin-bottom: 0;
    //margin-top: 1em;
    .icon{
      display: none;
    }
  }

  .business-name{
    width: 85%;
  }
  .icon{
    margin-top: 0;
  }

  .contact-icon{
    color: $grey-secondary;
  }

  .contact-name{
    font-size: $font-size-xs;
    color: $denim-primary;
  }

  .business-category{
    font-size: $typography-validation;
    font-weight: $font-weight-medium;
    color: $dark-grey;
    background: $light-grey;
    padding: 3px 8px;
    border-radius: $border-radius;
    width: fit-content;
  }

  .location{
    font-size: $font-size-xs;
  }

  .contact-info{
    padding-top: 1rem;
    margin-left: 0.5rem;
    margin-right: 1rem;
  }

  .contact-details{
    font-size: $typography-validation;
    font-weight: $font-weight-medium;
    word-break: break-word;
  }

  //Custom inline styles
  .inline {
    @include media-breakpoint-down(xs) {
      flex-wrap: nowrap;
      > :not(:first-child) {
        margin-left: #{$spacing-unit * 2}px;
        margin-top: 0;
      }
    }
    &.icons{
      width: 14%;
      position: absolute;
      top: 1.6rem;
      right: 1.5rem;
      @include media-breakpoint-down(md){
        position: relative;
        right: inherit;
        top: inherit;
        padding-bottom: 1rem;
      }
    }
  }

  //Typography
  h3{
    margin: 0.5rem 0;
    @include media-breakpoint-down(sm) {
      font-size: $font-size-md;
    }
  }

  p{
    margin: 0;
  }
}
.lookup .container:not(:last-child){
  padding-bottom: 1em;
}

//Special Instructions

.instruction{

  @include media-breakpoint-down(xs) {
    width: 100%;
  }
  .comments{
    font-size: $typography-regular;
    padding-top: 0.5em;
  }
}

.image{
  position: relative;
  width: 100%;

  .product-thumbnail {
    border: 1px solid $grey-secondary;
    padding: 1.5em;
    background-color: $white;
    margin-bottom: 1.5em;

    &:hover {
      transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }

    .product-image {
      margin-bottom: 1.5em;
      display: block;
      margin-left: auto;
      margin-right: auto;
      min-width: 150px;
      min-height: 150px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .card-title {
      text-align: center;
    }
  }

  .product-cost {
    display: none;
  }

  .fab{
    display: none;
  }

  &--product{
    margin-bottom: 2em;
    @include media-breakpoint-up(md){
      width: 31.5%;
    }

    @include media-breakpoint-down(md) {
      width: 49%;
    }

    @include media-breakpoint-down(sm) {
      width: 49%;
    }

    @include media-breakpoint-down(xs) {
      width: 100%;
    }
    &.check .product-thumbnail {
      border-color: $button-primary-background;
    }
    .fab{
      display: flex;
      position: absolute;
      top: -20px;
      right: -20px;
      z-index:5;
      border: 1px solid $button-primary-background;
      background-color: $white;
      color: $grey-primary;
      box-shadow: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      &:hover{
        background-color: $button-primary-background;
      }

      &.check {
        background-color: $button-primary-background;
        color: $button-primary-foreground;
        > span > svg {
          height:20px;
          width:20px;
        }
        &:hover {
          background-color: $button-primary-background-hover;
          border-color: $button-primary-background-hover;
          color: $button-primary-foreground-hover;
        }
        &[disabled]{
          background-color: $button-primary-background-disabled;
        }
      }
    }
    .product-cost{
      display: block;
    }
  }
}

.full-image {
  width: 70%;
  height: auto;
  margin: 0 auto;
  display: block;
  @include media-breakpoint-down(md){
    width: 80%;
  }
  @include media-breakpoint-down(xs){
    width: 90%;
  }
  @include media-breakpoint-down(xs){
    width: 100%;
  }
}
