$screen-xs-min: 0px;
$screen-sm-min: 600px;
$screen-md-min: 960px;
$screen-lg-min: 1280px;

$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);

@mixin media-breakpoint-down($breakpoint) {
	@if $breakpoint == 'xs' {
		@media screen and (max-width: $screen-xs-max) {
			@content;
		}
	} @else if $breakpoint == 'sm' {
		@media screen and (max-width: $screen-sm-max) {
			@content;
		}
	} @else if $breakpoint == 'md' {
		@media screen and (max-width: $screen-md-max) {
			@content;
		}
	} @else if $breakpoint == 'lg' {
		@media screen and (max-width: $screen-lg-min) {
			@content;
		}
	} @else {
		@content;
	}
}

@mixin media-breakpoint-up($breakpoint) {
	@if $breakpoint == 'xs' {
		@media (min-width: $screen-xs-min) {
			@content;
		}
	} @else if $breakpoint == 'sm' {
		@media screen and (min-width: $screen-sm-min) {
			@content;
		}
	} @else if $breakpoint == 'md' {
		@media screen and (min-width: $screen-md-min) {
			@content;
		}
	} @else if $breakpoint == 'lg' {
		@media screen and (min-width: $screen-lg-min) {
			@content;
		}
	} @else {
		@content;
	}
}
