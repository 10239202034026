$typography-title-size: 1.1rem;
$typography-subheading-size: 1rem;
$typography-body-size: 1.1rem;
$typography-button: 1rem;
$typography-validation: 0.875rem;
$typography-small: 0.75rem;
$typography-display1: 1.6rem;
$typography-display2: 1.8rem;
$typography-display3: 2.4rem;
$typography-display4: 3.2rem;
$typography-regular: 14px;
$typography-icon: 1rem;
$font:'Roboto', 'Helvetica', 'Arial', sans-serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-style-italic: italic;

$font-size-xxlg: 2rem;
$font-size-xlg: 1.6rem;
$font-size-lg: 1.4rem;
$font-size-md: 1.3rem;
$font-size-sm: 1.25rem;
$font-size-xs: 1.1rem;
$font-size-reg:0.975rem;

.typography,body {
	.light {
		font-weight: $font-weight-light;
	}

	.regular {
		font-weight: $font-weight-regular;
	}

	.medium {
		font-weight: $font-weight-medium;
	}

	.bold {
		font-weight: $font-weight-bold;
	}

	.italic {
		font-style: italic;
		font-weight: $font-weight-regular;
	}

	.h1,h1{
		font-size: $font-size-xxlg;
		font-weight: $font-weight-regular;
		&--subtitle{
			font-weight: $font-weight-light;
		}

		margin: 0 auto;
	}

	.h2, h2{
		font-size: $font-size-xlg;
		font-weight: $font-weight-regular;
		&.title {
			color: $denim-primary;
		}
		&--subtitle{
			font-weight: $font-weight-light;
		}
		@include media-breakpoint-down(md) {
			font-size: $font-size-xxlg;
		}
	}

	.h3, h3{
		font-size: $font-size-lg;
		font-weight: $font-weight-medium;
		&.header{
			margin: 0;
		}
	}

	.h4, h4{
		font-size: $font-size-sm;
		font-weight: $font-weight-medium;
		margin: 0.1em 0;
	}

	.h5, h5{
		font-size: $font-size-xs;
		font-weight: $font-weight-medium;
		margin: 0;
	}

	.paragraph, p{
		font-size: $font-size-reg;
		margin: 0 auto;
		letter-spacing:0.3px;
		line-height:1.5;
		&.no-actions{
			text-align: center;
			font-weight: $font-weight-light;
			//font-size: $font-size-xlg;
			padding-bottom: 0.5em;
			span{
				font-weight: $font-weight-medium;
			}
		}
	}
}

.title{
	color: $denim-primary;
}
/** Sizes **/

.sm{
	font-size: $font-size-sm;
}
.md{
	font-size: $font-size-md;
}
.lg{
	font-size: $font-size-lg;
}
.xlg{
	font-size:$font-size-xlg;
}
.xxlg{
	font-size:$font-size-xxlg;
}

/** Weights **/

.light {
	font-weight: $font-weight-light;
}

.regular {
	font-weight: $font-weight-regular;
}

.medium {
	font-weight: $font-weight-medium;
}

.bold {
	font-weight: $font-weight-bold;
}

.italic {
	font-style: italic;
	font-weight: $font-weight-regular;
}

/** Text Links **/

p{
	a{
		//color:inherit; /* um why was this here? */
	}
}

a,
.button-base{
	cursor: pointer;
	border: none;
	//white-space: nowrap;
	text-decoration: underline;
	color: $orange-primary;
	align-self: center;
	font-weight: $font-weight-medium;
	//margin-right: 0.5em;

	& > :not(:first-child) {
		//margin-left: #{$spacing-unit * 1}px;
	}

	&:hover {
		cursor: pointer;
		color: $orange-tertiary;
	}

	/**Variants**/
	&.link-denim {
		color: $denim-primary;
		&:hover {
			color: $denim-tertiary;
		}
	}
	&.link-red{
		color: $red-primary;
		&:hover {
			color: $red-tertiary;
		}
	}
	&.link-white{
		color: $white;
		&:hover {
			color: $denim-tertiary;
		}
	}
}

.hasClick {
	cursor: pointer;
}

a{
	white-space: inherit;
}
body{
	font-family:$font;
}



