/* calendar */
.rbc-calendar {
    --offset: 0; /* gets recalculated */
}
.rbc-calendar .rbc-time-view,
.rbc-calendar .rbc-month-view,
.rbc-calendar .rbc-agenda-table,
.rbc-calendar .rbc-agenda-content {
    background: white;
}

.rbc-calendar .rbc-today {
    background-color: white;
}

.rbc-calendar .rbc-allday-cell {
    display: none;
}

.rbc-calendar .rbc-current-time-indicator {
    height: 2px;
    background-color: #35327c;
    /*margin-top: 54px; !* height of column headers *!*/
    display: block !important;
    width: 100% !important;
    left: 0 !important;
}

.rbc-calendar .rbc-time-gutter .rbc-time-slot[data-time]::after {
    content: attr(data-time);
    position: absolute;
    border-radius: 26px 0 0 26px;
    background: #35327b;
    color: white;
    padding: 0 2px 0 6px;
    font-size: 0.825rem;
    right: -1px;
    margin-top: var(--offset); /* gets calculated */
}

.rbc-calendar .rbc-time-view-resources {
    position: relative;
    min-height: 360px;
}

.rbc-calendar .rbc-time-view-resources .rbc-time-content {
    /*position: unset;*/
}

.rbc-calendar .rbc-row-bg .rbc-day-bg,
.rbc-calendar .rbc-time-content .rbc-day-slot {
    background-color: #f8f9fb;
}

.rbc-calendar .rbc-day-slot .rbc-time-slot {
    border-color: #eeeeee;
}

.rbc-calendar .rbc-time-gutter .rbc-time-slot span.rbc-label {
    padding: 10px 14px;
    display: block;
    font-size: 0.8rem;
}

.rbc-calendar .rbc-row-bg .rbc-day-bg.rbc-off-range-bg {
    background-color: #edeef0;
}

.rbc-calendar .rbc-row-bg .rbc-day-bg.rbc-selected-cell {
    background-color: rgba(0, 0, 0, 0.2);
}

.rbc-calendar .rbc-row .rbc-now {
    font-weight: normal;
}

.rbc-calendar .rbc-row .rbc-current {
    font-weight: bold;
}

.rbc-calendar .rbc-row .rbc-now::before {
    content: 'Today';
    float: left;
    font-size: 10px;
    padding: 2px;
    color: #bbb;
    font-weight: normal;
}

.rbc-calendar .rbc-row .rbc-current::after {
    background-color: white;
    color: #bbb;
    content: 'Selected';
    font-size: 10px;
    font-weight: normal;
    padding: 2px;
    text-align: left;
    position: absolute;
    z-index: -1;
    width: calc(14.2857% - 2px);
    height: 200%;
    margin-left: calc(-14.2857% + 6px);
}

.rbc-calendar .rbc-row .rbc-current.rbc-now::after {
    padding-left: 2rem;
}

.rbc-calendar .rbc-row.rbc-time-header-cell {
    display: none; /* need to only apply to day view, not week views */
}

.rbc-calendar .rbc-row-resource {
    overflow: hidden;
    max-height: 50px;
}

.rbc-calendar .rbc-row-resource .rbc-header {
    padding: 0.25rem 0.5rem;
    border: none;
    font-weight: normal;
    font-size: 11px;
    overflow: visible;
    white-space: normal;
}

.rbc-calendar .rbc-row-resource .rbc-header::first-line {
    font-size: 15px;
}

.rbc-calendar .rbc-month-view .rbc-header,
.rbc-calendar .rbc-agenda-table .rbc-header {
    padding: 1rem;
    background: white;
}

.rbc-calendar .rbc-event-continues-prior:before {
    content: '\2039';
    /*left: 0;*/
    margin-top: 1rem;
}

.rbc-calendar .rbc-event-continues-after:after {
    content: '\203A';
    right: 0.25rem;
    margin-top: -3.5rem;
}

.rbc-calendar .rbc-event-continues-prior:before,
.rbc-calendar .rbc-event-continues-after:after {
    position: absolute;
    font-size: 2rem;
    font-weight: bold;
}

.rbc-calendar .rbc-month-view .rbc-event-continues-prior:before {
    margin-top: -0.5rem;
}

.rbc-calendar .rbc-month-view .rbc-event-continues-after:after {
    /*top: 0.75rem;*/
    margin-top: -2rem;
}

.rbc-calendar .rbc-month-view .rbc-row-segment {
    width: 14.2857%;
}

.rbc-calendar .rbc-month-view .rbc-show-more {
    font-size: 75%;
    margin-left: 12px;
    color: unset;
    text-decoration: none;
}

.rbc-calendar .rbc-month-view .rbc-overlay-header {
    background: #3f51b5;
    color: white;
}

/** Agenda / Locations view **/

.rbc-calendar .rbc-agenda-view table.rbc-agenda-table .rbc-header {
    font-weight: normal;
    padding: 1rem;
}

.rbc-calendar .rbc-agenda-view table.rbc-agenda-table .rbc-header:first-child {
    width: auto !important;
    min-width: 13rem;
}
.rbc-calendar .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-date {
    background-color: #f8f9fb;
    position: sticky;
    top: 53px;
}

.rbc-calendar .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
    text-transform: none;
    white-space: unset;
    min-width: 13rem;
}

.rbc-calendar .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
    padding: 10px;
}

.rbc-calendar .rbc-agenda-view table.rbc-agenda-table .rbc-header,
.rbc-calendar .rbc-agenda-view table.rbc-agenda-table tbody > tr > td.rbc-agenda-event-cell {
    min-width: 38rem;
    width: 100%;
}

.rbc-calendar .rbc-agenda-event-cell .rbc-agenda-slot {
    display: inline-block;
    border-left: 0.2em solid #e9e9e9;
    height: 44px;
}

.rbc-calendar .rbc-agenda-event-cell span.rbc-agenda-slot:last-of-type {
    border-right: 0.02em solid #e9e9e9;
}

/* calendar toolbar */
.rbc-calendar .rbc-toolbar {
    position: relative;
    padding: 0;
    margin-bottom: 0;
    justify-content: space-between;
    flex-direction: row;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group {
    /*margin: auto;*/
}

.rbc-calendar .rbc-toolbar .rbc-btn-group.align-left {
    position: absolute;
    left: 0;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group.align-right {
    position: absolute;
    right: 0;
}

.rbc-btn-group > button {
    border-radius: 30px;
    cursor: pointer;
    height: 56px;
}

.rbc-btn-group > button:not(:last-of-type) {
    border-right: 0;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group button {
    padding: 0 0.88rem;
    background-color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group button.rbc-active {
    background: #35327c;
    color: white;
    border-color: #cccccc;
}

/* calendar event */
.rbc-calendar .rbc-event-label {
    display: none;
}

.rbc-calendar .rbc-event {
    border: 0;
    border-radius: 0;
    padding: 0;
    color: #444;
    background: transparent;
}

.rbc-calendar .rbc-event-content > div,
.rbc-calendar .rbc-agenda-event-cell .rbc-agenda-event > div {
    border-left: 4px solid black;
    background-color: #90caf9;
    padding: 0.5rem;
    height: 100%;
    cursor: pointer;
}

.rbc-calendar .rbc-agenda-event-cell .rbc-agenda-event > div {
    height: 2rem;
    white-space: nowrap;
}

.rbc-calendar .rbc-event-content > div:focus-visible,
.rbc-calendar .rbc-event-content > div.focus-visible {
    outline-offset: -1px;
    outline: black auto 1px;
}

.rbc-calendar .rbc-agenda-event-cell .rbc-agenda-event > div:focus-visible,
.rbc-calendar .rbc-agenda-event-cell .rbc-agenda-event > div.focus-visible {
    outline-offset: 6px;
    z-index: 1;
    position: relative;
    outline: black auto 1px;
}

.rbc-calendar .rbc-agenda-event-cell .rbc-agenda-event > div:focus-visible > div,
.rbc-calendar .rbc-agenda-event-cell .rbc-agenda-event > div.focus-visible > div {
    background: white;
    float: left;
    padding: 0 6px 6px 0;
    color: black !important;
}

.rbc-calendar .rbc-agenda-event-cell .rbc-agenda-event > div.focus-visible,
.rbc-calendar .rbc-event-content > div.focus-visible {
    outline-color: gray;
}

.rbc-calendar .rbc-agenda-event-cell .rbc-agenda-event {
    position: absolute;
    top: 0;
}

.rbc-calendar .rbc-event .category {
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
}

.rbc-calendar .rbc-event .title {
    font-weight: bold;
    margin-bottom: 0.125rem;
}

.rbc-calendar .rbc-event .details {
    font-size: 0.6667rem;
}

.rbc-calendar .rbc-month-view .rbc-event {
    height: 1.45rem;
    overflow: hidden;
}

.rbc-calendar .rbc-month-view .rbc-event-content > div {
    padding-top: 0;
    padding-bottom: 2px;
}

.rbc-calendar .rbc-month-view .rbc-event-content > div div {
    display: inline-block;
    margin-right: 0.125rem;
    font-size: 75%;
}

.rbc-calendar .rbc-month-view .rbc-event .category {
    display: none;
}

/* calendar cards */
.cal-card {
    margin: 1rem 1rem 1rem 1rem;
    width: 22rem;
    max-width: 100%;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    background: white;
    border-radius: 5px;
}

.cal-card-head {
    padding: 1rem;
    padding-left: 3.25rem;
    background-color: #90caf9;
    border-radius: 5px 5px 0 0;
}

.cal-card-drag {
    position: absolute;
    left: 38px;
    top: 36px;
    cursor: move;
    color: white;
    opacity: 0.5;
}

.cal-card-close {
    position: absolute;
    right: 6px;
    top: 6px;
    cursor: pointer;
}

.cal-card-del {
    color: red;
    cursor: pointer;
    position: absolute;
    bottom: 1.25rem;
    right: 1.5rem;
}

.cal-card-close span {
    background: white;
    border-radius: 5rem;
    font-size: 2rem;
}

.cal-card-head p {
    color: white;
}

.cal-card-body {
    padding: 1rem;
    padding-left: 3.25rem;
}

.cal-card button > span:first-of-type {
    box-shadow: inset 0 0 0px 20px rgba(255, 255, 255, 0.33);
    border-radius: 5rem;
    width: 100%;
    height: 100%;
    padding-top: 0.4rem;
    padding-bottom: 4px;
    display: inline-block;
}

/* pickers */
.pickers {
    display: flex;
    justify-content: space-around;
    position: relative;
}

.pickers > div {
    background: white;
    position: absolute;
    z-index: 999;
    top: -1rem;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.arrowBtn {
    padding: 12px !important;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group .filterBtn {
    border-radius: 30px;
    background-color: #35327c;
    color: #ffffff;
    position: relative !important;
    padding: 13px !important;
    height: 56px;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group .filterBtn svg {
    margin: 0 4px -2px 4px;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group .filterBtn.filterBtnOff {
    background-color: #fff;
    color: #373a3c;
}

.ApptBtn {
    border-radius: 30px !important;
    color: #35327c !important;
    border-color: #35327c !important;
    margin-right: 10px !important;
}

.rbc-time-header {
    height: 50px;
}

.rbc-time-header-content {
    border-left: 0.02em solid #ddd !important;
    min-width: 121px !important;
    justify-content: center;
}

.rbc-time-content > * + * > * {
    border-left: 0.02em solid #ddd !important;
}

.rbc-day-slot .rbc-time-slot {
    border-top: 0.02em solid #ddd !important;
}

.rbc-timeslot-group {
    border-bottom: 0.02em solid #ddd !important;
}

.rbc-time-view-resources .rbc-day-slot {
    min-width: 120px;
    transition: 0.5s;
}

span.bold {
    font-weight: bold;
}

.rbc-btn-group.calWidth {
    width: 100%;
}

.rbc-btn-group.calWidth .day {
    width: 40%;
}

.rbc-toolbar.toolbar-2.print-hide {
    margin: 0;
}

body .rbc-btn-group + .rbc-btn-group {
    margin: 0;
}

.rbc-agenda-view > table {
    position: sticky;
    top: 0px;
    z-index: 1;
}

@media screen and (max-width: 1536px) {
    .rbc-calendar .rbc-agenda-view table.rbc-agenda-table .rbc-header:first-child {
        min-width: 8.5rem;
    }

    .rbc-calendar .rbc-agenda-view table.rbc-agenda-table .rbc-header .odd {
        display: none;
    }

    .rbc-calendar .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
        min-width: 8.5rem;
    }
}

@media screen and (max-width: 400px) {
    .rbc-btn-group .today {
        display: none;
    }
}
