//Data Highlight
.data-highlight{
  text-align: center;
  height:100%;
  display:flex;
  flex-direction: column;
  justify-content:center;
  .data{
    font-weight: $font-weight-bold;
    font-size: $typography-display1;
    color: $great;
    letter-spacing: 1px;
    padding: #{$spacing-unit * 1.5}px 0;
    &--orange {
      color: $bad;
    }

    &--green{
      color: $good;
    }

    &--yellow{
      color: $ok;
    }

    &--red{
      color: $fail;
    }
  }
}