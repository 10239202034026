//colours from style guide
$parsons-primary:#35327C;
$parsons-secondary:#EFF8FD;
$parsons-tertiary:#EFF8FD;

$denim-primary: #7d9cc4;
$denim-secondary: #a1bbdc;
$denim-tertiary: #5a769b;

$aqua-primary: #48c3d1;
$aqua-secondary: #6dd6e2;
$aqua-tertiary: #399ea5;
$aqua-quaternary: #F0FBFC;

$orange-primary: #f89e53;
$orange-secondary: #ffba81;
$orange-tertiary: #e87b28;
$orange-quaternary: #FDF2EB;

$mustard-primary: #ded062;
$mustard-secondary: #f6ec9d;
$mustard-tertiary: #aba152;

$green-primary: #26cc6f;
$green-secondary: #67f7a6;
$green-tertiary: #14a554;
$green-quaternary: #E9FAF0;

$stann-primary: #ffbd26;
$stann-secondary: #ffe788;
$stann-tertiary: #d59e20;
$stann-quaternary: #FFFCF5;

$city-primary: #e67d40;
$city-secondary: #fbb891;
$city-tertiary: #bc581d;

$red-primary: #e64040;
$red-secondary: #f8adad;
$red-tertiary: #b51a1a;
$red-quaternary: #FDEBEB;

$ladies-primary: #8b3983;
$ladies-secondary: #da83d2;
$ladies-tertiary: #78126d;
$ladies-quaternary: #F7EDFD;

$tender-primary: #5C0D10;
$tender-secondary: #FFE6E7;

$easy-primary: #DE1076;
$easy-secondary:#fdf1f7;

$progress-blue:#1e73be;

$black: #000000;
$almost-black: #2F2F2F;
$dark-grey: #555555;
$dark-grey2: #777777;
$grey-primary: #cdcdcd;
$grey-secondary: #d5d5d5;
$light-grey: #eeeeee;
$almost-white: #FAFAFA;
$grey-red: #D8CACA;
$white: #ffffff;

//app global colours
$app-background: $denim-primary;
$app-background-secondary: #e6ecf4;
$app-active: $denim-primary;
$app-hover: $light-grey;
$app-selected: $light-grey;
$app-bucket: #e6ecf488;

//form element colours
$error-foreground: $red-primary;
$error-background: $red-quaternary;

$warning-background: $orange-quaternary;
$warning-foreground: $orange-primary;

$good-background: $green-quaternary;
$good-foreground: $green-primary;

$info-background: $aqua-quaternary;
$info-foreground: $aqua-primary;

$fire-border: #D9B052;
$fire-background:#FFFEFB;

//table colours
$table-header-foreground: $white;
$table-header-background: $aqua-primary;
$table-header-border: $denim-primary;

$table-header-nested-foreground: $white;
$table-header-nested-background: $aqua-secondary;
$table-header-nested-border: $denim-secondary;

$table-row-foreground: $black;
$table-row-background: rgba(72, 195, 209, 0.1); //aqua 10%
$table-row-hover: rgba(72, 195, 209, 0.2);
$table-row-border: $aqua-primary;

$table-row-nested-foreground: $black;
$table-row-nested-background: $white;
$table-row-nested-border: $aqua-primary;
$table-row-nested-hover: rgba(72, 195, 209, 0.2);

//modal colours
$modal-background: $almost-white;
$modal-foreground: $dark-grey;
$modal-foreground-title: $denim-primary;
$modal-background-header: $denim-primary;
$modal-foreground-header: $white;
$modal-background-header-active: $almost-white;
$modal-foreground-header-active: $denim-primary;
$modal-background-header-hover: $denim-secondary;

//button colours
$button-primary-foreground: $white;
$button-primary-background: $aqua-primary;
$button-primary-foreground-hover: $white;
$button-primary-background-hover: $aqua-tertiary;
$button-primary-background-disabled: $aqua-tertiary;

$button-secondary-foreground: $orange-primary;
$button-secondary-background: $white;
$button-secondary-border: $orange-primary;
$button-secondary-foreground-hover: $white;
$button-secondary-background-hover: $orange-primary;
$button-secondary-border-hover: $orange-primary;
$button-secondary-background-disabled: $orange-tertiary;

$button-tertiary-foreground:  $white;
$button-tertiary-background: $orange-secondary;
$button-tertiary-foreground-hover:  $white;
$button-tertiary-background-hover: $orange-tertiary;
$button-tertiary-background-disabled: $orange-tertiary;

$button-quaternary-foreground:  $white;
$button-quaternary-background: $denim-primary;
$button-quaternary-foreground-hover:  $white;
$button-quaternary-background-hover: $denim-tertiary;
$button-quaternary-background-disabled: $denim-tertiary;

$button-urgent-foreground:  $white;
$button-urgent-background: $orange-primary;
$button-urgent-foreground-hover:  $white;
$button-urgent-background-hover: $denim-primary;
$button-urgent-background-disabled: $grey-secondary;

$button-confirmation-foreground: $white;
$button-confirmation-background: $green-tertiary;
$button-confirmation-foreground-hover: $white;
$button-confirmation-background-hover: $green-primary;
$button-confirmation-background-disabled: $grey-secondary;

$button-link-foreground: #35327c;
$button-link-foreground-hover: #9693e9;

//card colours
$star-yellow:#FFDD26;

//sizes
$size-header-height: 72;
$size-footer-height: 80;
$size-drawer-width: 250;
$size-tasks-drawer-width: 350;
$size-drawer-width-minimized: 50;

//spacing
$spacing-unit: 8;
$size-tab-height: 56;

//z indices
$z-index-modal: 1205;
$z-index-drawer: 1200;
$z-index-footer: 1201;

//borders
$border-radius: 3;

/**Metrics Status Colours**/
$great:$denim-primary;
$good:#44AF69;
$ok:#F7DD60;
$bad:$orange-primary;
$fail:#D14F57;

$metric-grey: #37474F;

