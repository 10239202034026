.task-bucket {
    background-color: $white;
    padding: 1.5em;
    margin-right: 1%;
    width: 19%;
    height: 100%;
    @media screen and (max-width: 1660px) {
        width: 26%;
    }
    @include media-breakpoint-down(lg) {
        height: auto;
        width: 100%;
    }
    .nestedButt {
        margin: -10px -16px -10px 0;
    }
    .tasknumber {
        display: inline-block;
        margin-right: 4px;
        font-weight: 500;
    }
    h3 {
        font-size: 1.15rem;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 1rem;
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
        color: $denim-primary;
    }

    & nav > div > div > div > div > div {
        padding-left: 40px;
    }

    &.desktop-bucket {
        //@media screen and (max-width: 2000px) {
        //    display: none;
        //}
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}

.task-draw::-webkit-scrollbar {
    width: 0;
    height: 0;
}
