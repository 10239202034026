.textfield {
    display: flex;

    > :first-child {
        width: 100%;
    }

    .input {
        background-color: $white;
        border-radius: #{$border-radius}px;
        border-color: $light-grey;
        border-style: solid;
        border-width: 1px;
        padding: 2px #{$spacing-unit}px;
        transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        &--multiline {
            padding: 1rem;
            border-radius: #{$border-radius}px;
            border-color: $light-grey;
            border-style: solid;
            border-width: 1px;
            background-color: $white;
        }

        &--focused {
            border-radius: #{$border-radius}px;
            border-color: $denim-secondary;
            box-shadow: 0 0 0 0.05rem rgba(65, 59, 190, 0.1);
            background-color: $white;
        }

        &.error {
            //border-color: $error-foreground;
        }

        &.suggested {
            //border-color: $warning-foreground;
        }

        &.optional {
            //border-color: $good-foreground;
        }

        .add-new-button {
            display: inline-block;
            margin: 0 -4px 0 0;
            border-radius: 20px 4px 4px 20px;
            height: 28px;
            min-width: unset;
            white-space: pre;
            padding: 3px 8px;
            text-align: center;
            border: 1px solid $button-secondary-border;
            font-size: 0.75em;
            text-decoration: none;
        }

        /* hide button unless hover or entering text */
        .add-new-button {
            width: 0;
            padding: 3px 0;
            opacity: 0;
            overflow: hidden;
            transition: opacity 0.4s, width 0.5s ease-out 0s;
        }
        &:hover .add-new-button,
        input:focus + div .add-new-button {
            width: 94px;
            opacity: 1;
            transition: opacity 0.25s;
        }
    }

    .icon > svg {
        height: 1rem;
        width: 1rem;
    }

    .validation-label {
        border-width: 0 1px 1px 1px;
        border-radius: 0 0 #{$border-radius}px #{$border-radius}px;
        border-style: solid;
        margin: 0 3px;
        padding: #{$spacing-unit}px;
        &.error {
            border-color: $error-foreground;
            color: $error-foreground;
        }
        &.suggested {
            border-color: $warning-foreground;
            color: $warning-foreground;
        }
        &.optional {
            border-color: $good-foreground;
            color: $good-foreground;
        }
    }
}

.input {
    .label {
        z-index: 1;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        overflow: hidden;
        cursor: text;
        pointer-events: none;
        top: 2px;
        left: #{$spacing-unit}px;
        color: $dark-grey;
        margin-bottom: #{$spacing-unit * 2}px;
        transition: transform 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, color 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
            padding 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
            background-color 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
            border-radius 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

        > svg {
            display: none;
            font-size: 20px;
        }
        > span {
            white-space: nowrap;
        }
        > :not(:first-child) {
            margin-left: #{$spacing-unit / 2}px;
        }
    }

    .label--shrink {
        top: -10px;
        left: 0;
        height: 34px;
        padding: 2px #{$spacing-unit}px;
        background-color: $app-active;
        color: $white !important;
        border-radius: #{$border-radius}px #{$border-radius}px 0 0;
        margin-bottom: 0;
        z-index: 1;
        cursor: pointer;
        pointer-events: auto;

        &.error {
            background-color: $error-foreground;
        }

        &.suggested {
            background-color: $warning-foreground;
        }

        &.optional {
            background-color: $good-foreground;
        }

        svg {
            display: inline;
        }
    }
}

.input-adornment--end {
    display: flex;
    background-color: $aqua-primary;
    height: 36px;
    width: 3.5em;
    border-radius: 0 #{$border-radius}px #{$border-radius}px 0;
    margin-right: #{-$spacing-unit}px;
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
        color: $white;
        padding: 0.1em;
    }
    &:hover {
        cursor: pointer;
        background-color: $aqua-tertiary;
    }
}

.readonly {
    .value {
        color: $dark-grey2;
        font-style: $font-style-italic;
    }
}
