.separator {
	display: block;
	background-color: $grey-primary;

	&--horizontal {
		margin: 0 0 #{$spacing-unit}px 0;
		width: 100%;
		height: 1px;
	}

	&--vertical {
		margin: 0 #{$spacing-unit}px;
		width: 1px;
		height: 100%;
	}
}
