.linear-progress-indicator {
	width: 100%;

	.progress-indicator {
		background: $orange-primary;
		border-radius: 5px;
		color: #ffffff;
		padding: 3px 15px;
		font-size: 12px;
		display: inline-block;
		line-height: normal;
		transform: translate(-25px);
		width: 50px;
		margin-bottom: 10px;

		> span {
			width: 100%;
			display: inline-block;
			text-align: center;
		}

		> div {
			background: $orange-primary;
			display: inline-block;
			width: 10px;
			height: 10px;
			position: absolute;
			transform: rotate(45deg);
			bottom: -4px;
			z-index: -1;
			left: 38%;
		}
	}

	.progress-bar {
		height: 18px;
		background: $denim-secondary;
		> :first-child {
			height: 18px;
			background: $denim-primary;
		}
	}
}
