.workqueue-page {
	@import 'breakpoints';
	@import 'theme';
	@import 'typography';
	@import 'addLabel.scss';
	@import 'taskList.scss';
	@import 'taskActions.scss';
	@import 'taskBucket.scss';
	@import 'taskCard.scss';
	@import 'taskChecklist.scss';

  display:flex;
  height: 100%;
  flex-direction: column;

    @include media-breakpoint-down(sm) {
        .flex-container.full-height.wq-page {
            height: auto;
            overflow: visible;
        }
    }
}
