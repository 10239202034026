.clearfix {
  overflow: auto;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

/** Text align right **/

.align-right{
   text-align: right;
 }

.align-left{
  text-align: left;
}

/** padding **/

.padding-sm{
  padding: 1em;
}

.padding-md{
  padding: 2em;
}

.padding-lg{
  padding: 3em;
}
