.clearTable {
  border-spacing: 0;
  border-collapse: separate;
  width: 100%;
  font-size: $typography-validation;

  th {
    border: none;
  }
  td {
    border: none;
  }
  .row {
    &--header {
      text-align: left;
      font-weight: $font-weight-medium;
      font-size: $font-size-xs;
      > .td {
        background-color: none;
        color: $black;
      }
      td:first-child {
        border-top-left-radius: #{$border-radius}px;
      }
      td:last-child {
        border-top-right-radius: #{$border-radius}px;
      }
    }

    &--normal {
      > .td {
        color: $table-row-foreground;
        border-color: $table-row-border;
      }
      &:hover > .td {
        background-color: $table-row-hover;
        cursor: pointer;
      }
    }

    &--nested {
      > .td {
        background-color: $table-row-nested-background;
        color: $table-row-nested-foreground;
        border-color: $table-row-nested-border;
      }
      &:hover > .td {
        background-color: $table-row-nested-hover;
        cursor: pointer;
      }
    }

    &--padded {
      > .td {
        padding: #{$spacing-unit}px;
      }
    }

    //Responsive table styles
    @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
      border-bottom: 1.5px solid $table-row-border;
      margin: 0 0 1rem 0;

      &:nth-child(odd){
        //border: 1.5px solid $denim-primary;
      }

      /* Force table to not be like tables anymore */
      table, thead, tbody, th, td, tr {
        display: block;
      }

      /* Hide table headers (but not display: none;, for accessibility) */
      &--header {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      .td {
        /* Behave  like a "row" */
        border: none;
        //border-bottom: 1px solid $table-row-border;
        position: relative;
        padding-left: 50%;
      }

      .td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 0;
        left: 0;
        width: 47%;
        padding-right: 0px;
        word-break: break-word;
        font-weight: $font-weight-medium;
        font-size: $font-size-xs;
        height: -webkit-fill-available;
        padding-left: 6px;
        padding-top: 6px;
        content:attr(data-label);
      }
    }
  }

  .td {
    &--padded {
      padding: #{$spacing-unit}px;
    }
  }
}

/** Brand Dot **/
.brandDot{
  display:inline-block;
  width: 13px;
  height: 13px;
  background-color:$denim-primary;
  border-radius: 100%;
  &--parsons{
    background:$parsons-primary;
  }
  &--stc{
    background:$stann-primary;
  }
}
