/* simple modal styling */
.modal {
    &:not(.dialog-modal) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        //min-height: 50vh;
        max-height: 90vh;
        min-width: 863px;
        max-width: 90vw;
        @include media-breakpoint-down(sm) {
            width: 90vw;
            min-width: unset;
        }
    }
    .fab.close {
		background-color: $button-primary-background;
		border-color: $button-primary-background;
		color: $button-primary-foreground;
		&:hover {
			background-color: $button-primary-background-hover;
			border-color: $button-primary-background-hover;
			color: $button-primary-foreground-hover;
		}
		&[disabled]{
			background-color: $button-primary-background-disabled;
        }
        position: absolute;
        top: -14px;
        right: -14px;
        z-index:5;
    }

    .paper {
        position: relative;
        border-radius: #{$border-radius}px;
        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
        outline: none;
        background-color: $modal-background;
        color: $modal-foreground;
        padding:#{$spacing-unit * 5}px;
        overflow: hidden;
        overflow-y: auto;
        max-height: 90vh;
        min-height: 20vh;

        @include media-breakpoint-down(sm) {
            max-height: 75vh;
            padding:#{$spacing-unit * 3}px;
        }
    }

    &--filter{
        max-width: 450px;
        h2, h3{
            margin-top: 0;
            color: $black;
        }
        .checkbox{
            display: inline-flex;
            align-self: center;
            width: 50%;
        }
    }

    &:focus{
        outline: 0px;
    }

    &.appointment-modal{
        max-width: 800px;

        p{
            color: $dark-grey2;
            align-items: center;
            display: flex;
            margin-left: 1em;
            margin-top: 0.5em;
            @include media-breakpoint-down(md) {
                margin-left: inherit;
                margin-top: inherit;
                float: right;
                margin-right: 48%;
            }
        }
    }

    div.modal-footer.paper {
        padding: #{$spacing-unit * 2}px #{$spacing-unit * 4}px;
        border-radius: 0 0 #{$border-radius}px #{$border-radius}px;
        bottom: 0;
        min-height: unset;
        overflow: visible;
        @include media-breakpoint-down(xs) {
            padding: 0.75em;
            .inline {
                gap: 0.75rem;
            }

            button {
                margin: 0;
                padding: 0.75rem 0;
                > span > svg {
                    margin: 0;
                    min-width: 2rem;
                    min-height: 2rem;
                }

                > span > span.button-txt {
                    display: none;
                }
            }
        }
    }
}

/* styling for the stepped version of a modal */
.modal.modal--stepped {

    display:flex;
    flex-direction: column;
    //width: 100%;

    // adding a max height because headers footers get cut off when content is taller
    max-height: 95vh;

    .modal-header {
        display:flex;

        @include media-breakpoint-down(sm) {
            display: inline-block;
        }

        > :first-child .step-title-button {
            border-top-left-radius: #{$border-radius * 3}px;
            @include media-breakpoint-down(sm) {
                border-top-left-radius: unset;
            }
        }
        > :last-child .step-title-button {
            border-top-right-radius: #{$border-radius * 3}px;
            @include media-breakpoint-down(sm) {
                border-top-right-radius: unset;
            }
        }
        .step-title-button {
            z-index:4;
            border-radius: 0;
            background-color: $modal-background-header;
            color: $modal-foreground-header;
            text-transform:capitalize;
            font-size: $typography-display1;
            font-weight: $font-weight-light;
            padding: #{$spacing-unit * 4}px #{$spacing-unit * 5}px;
            border-bottom: 2px solid $modal-background-header;
            width: max-content;
            &:hover {
                background-color: $modal-background-header-hover;
            }
            &.active {
                background-color: $modal-background-header-active;
                font-weight: $font-weight-regular;
                color: $modal-foreground-header-active;
                @include media-breakpoint-down(sm) {
                    background-color: $modal-background-header;
                    color: $modal-foreground-header;
                    font-weight: $font-weight-light;
                    border-top-left-radius: #{$border-radius * 3}px;
                    border-top-right-radius: #{$border-radius * 3}px;
                }
            }
            &:not(.active) {
                @include media-breakpoint-down(sm) {
                    display: none;
                }
                //box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
            }
            @include media-breakpoint-down(sm) {
                padding: #{$spacing-unit * 3}px #{$spacing-unit * 4}px;
                width: 100%;
            }
        }
    }

    .modal-content {
        &.paper {
            border-radius: 0 #{$border-radius}px #{$border-radius}px #{$border-radius}px;
            padding: #{$spacing-unit * 4}px #{$spacing-unit * 5}px;

            @include media-breakpoint-down(sm) {
                padding: #{$spacing-unit * 3}px #{$spacing-unit * 4}px;
            }
            overflow: auto;
            max-height: 100%;
        }

        flex-grow: 1;

        .step-title {
            padding-bottom: 1em;
            .step-title-number {
                color:$modal-foreground-title;
            }

            .step-title-detail{
              font-weight: $font-weight-light;
            }
        }

        .step-content {
            margin: 0 -12px;
        }

        .hover-search-results {
            position: relative;
            top: 0;
        }

        .flex-container{
            .billing-details{
                width: 18.4%;
                margin-right: 2%;
              padding-bottom: 1em;
              @include media-breakpoint-down(md) {
                width: 30.4%;
              }
              @include media-breakpoint-down(sm) {
                width: 48%;
              }
              @include media-breakpoint-down(xs) {
                width: 100%;
                margin-right: 0;
              }
            }
            > .billing-details:last-child {
              margin-right: 0;
            }
        }
    }
}

/* styling for the alert version of a modal */
.modal.modal--alert {
    max-width: 600px;
    width: 90%;
    .alert-content{
        display:flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        > :not(:first-child){
            margin-top:#{$spacing-unit * 2}px;
        }
        .icon > svg {
            height: 75px;
            width: 75px;
        }
        p{
            padding-bottom: 1em;
            color: $black;
        }
        .inline{
            flex-wrap: nowrap;
        }
    }

    .paper {
        background-color: $modal-background;
        border-width: 1px;
        border-style: solid;
        padding:#{$spacing-unit * 4}px;
        padding-right:#{($spacing-unit * 4)-12}px;
    }

    &--primary {
        .paper {
            border-color: $aqua-primary;
            background-color: $aqua-quaternary;
        }
        .fab.close {
            background-color: $aqua-primary;
        }
        .icon {
            color:$aqua-primary !important;
        }
    }
    &--save {
        .paper {
            border-color: $good-foreground;
            background-color: $green-quaternary;
        }
        .fab.close {
            background-color:$good-foreground;
        }
        .icon {
            color:$good-foreground !important;
        }
    }
    &--warning {
        .paper {
            border-color: $warning-foreground;
            background-color: $orange-quaternary;
        }
        .fab.close {
            background-color: $warning-foreground;
        }
        .icon {
            color: $warning-foreground !important;
        }
    }
    &--error {
        .paper {
            border-color: $error-foreground;
            background-color: $red-quaternary;
        }
        .fab.close {
            background-color: $error-foreground;
        }
        .icon {
            color:$error-foreground !important;
        }
    }
}


/* styling for the primary version of a modal */
.modal.modal--primary, .modal.modal--task {
    width: 90%;
    max-width: 800px;
    max-height: 95vh;
    position: relative;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down(xs) {
        width: 100%;
        top: 0;
        left: 0;
        transform: none;
    }
    .paper {
        border-radius: 0;
        padding: 0;
        max-width: 800px;
    }

    .modal-header {
        display:flex;
        flex-direction: row;
        border-top-right-radius: #{$border-radius}px;
        border-top-left-radius: #{$border-radius}px;
        background-color: $modal-background-header;
        color: $modal-foreground-header;
        font-size: $typography-display1;
        padding: #{$spacing-unit * 2}px #{$spacing-unit * 4}px;
        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
        max-width: 800px;

        .inline{
            margin-bottom: 0.2em;
        }
        .header {
            color: $modal-foreground-header;
            font-weight: $font-weight-light;
            font-size: $typography-title-size;
            @include media-breakpoint-down(xs){
                font-size: $typography-small;
            }
        }

        .subtitle {
            color: $modal-foreground-header;
            font-size: $typography-display3;
            @include media-breakpoint-down(xs){
                font-size: 1.75rem;
            }
        }

        .task-star {
            color: $star-yellow;
            float: right;
            @include media-breakpoint-down(xs){
                padding: 0;
            }
            svg{
                width: 2.5em;
                height: 2.5em;
                @include media-breakpoint-down(xs){
                    width: 1.8em;
                    height: 1.8em;
                }
            }
        }

        .title-container{
            width: 80%;
        }

        .star-container{
            width: 20%;
            margin-right: 0;
        }
    }

    .modal-content {
        padding: #{$spacing-unit * 3}px #{$spacing-unit * 4}px;
        flex-grow: 1;
        overflow: auto;
        max-width: 800px;
        &.paper{
            max-height: 85.5vh;
            @include media-breakpoint-down(sm) {
                padding: #{$spacing-unit * 2}px 0;
                form {
                    margin-bottom: 5em;
                }
            }
        }
    }
}

.modal.modal--primary .modal-header {
    flex-direction: column;
}

//Styling for Filter popover
.popover {
    .fab.close {
        background-color: $button-primary-background;
        border-color: $button-primary-background;
        color: $button-primary-foreground;
        &:hover {
            background-color: $button-primary-background-hover;
            border-color: $button-primary-background-hover;
            color: $button-primary-foreground-hover;
        }
        &[disabled]{
            background-color: $button-primary-background-disabled;
        }
        position: absolute;
        top: 10px;
        right: 10px;
        z-index:5;
    }

    .paper {
        position: relative;
        border-radius: #{$border-radius}px;
        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
        outline: none;
        background-color: $modal-background;
        color: $modal-foreground;
        padding:#{$spacing-unit * 5}px;
        padding-right:#{($spacing-unit * 5)-12}px;
    }

    &--filter{
        max-width: 450px;
        //@include media-breakpoint-down(sm) {
        //    position: absolute;
        //    width: 100%;
        //    top: 0;
        //    left: 0;
        //    transform: none;
        //}
        .fab.close{
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        h2, h3{
            margin-top: 0;
            color: $black;
        }

        h3{
            margin-top: 0;
            margin-bottom: 0.25em;
        }
    }

    &:focus{
        outline: 0px;
    }
}

.email-modal{
    .paper{
        max-width: 900px;
        @include media-breakpoint-down(md){
            max-width: none;
        }
    }
}
